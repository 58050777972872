import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/pricing.module.css";
import {
  ArrowLeft,
  Check,
  Star,
  Users,
  Zap,
  Shield,
  Database,
  HelpCircle,
  Clock,
  Book,
  Activity,
  MessageSquare,
} from "react-feather";

const PRICING_PLANS = [
  {
    name: "Basic",
    price: "$2.49",
    period: "per month",
    description:
      "Perfect for casual learners who want to try AI-based quiz features.",
    features: [
      "10 AI quizzes per month",
      "Basic flashcard creation",
      "Core study library",
      "Standard email support (48h)",
      "Text-to-quiz conversion",
      "Basic features only",
      "Perfect for trying out AI quizzes",
    ],
    highlighted: false,
    action: "Start Learning",
    // Replace with your Stripe Payment Link:
    link: "https://buy.stripe.com/eVa9Bf4Km3D21bi000",
  },
  {
    name: "Bramlo+",
    price: "$9.99",
    period: "per month",
    description:
      "Ideal for active learners who need unlimited quizzes and advanced insights.",
    features: [
      "Unlimited AI quizzes",
      "Smart flashcard creation",
      "Advanced study materials",
      "Spaced repetition system",
      "Performance analytics",
      "Priority support",
      "Text, audio & image conversion",
    ],
    highlighted: false,
    action: "Go Pro",
    // Replace with your Stripe Payment Link:
    link: "https://buy.stripe.com/28o8xb5Oqb5u07eeUV",
  },
  {
    name: "Bramlo+ Annual",
    price: "$6.99",
    period: "per month",
    description:
      "Save 30% with annual billing. Best value for dedicated learners.",
    features: [
      "All Bramlo+ features included",
      "Early access to new features",
      "Exclusive webinars/workshops",
      "Extended analytics history",
      "Priority 24/7 support",
      "Save 30% vs monthly",
    ],
    highlighted: true,
    action: "Best Value",
    // Replace with your Stripe Payment Link:
    link: "https://buy.stripe.com/8wM14J1yaa1q5ryaEG",
    annualNote: "billed annually at $83.88/year",
  },
  {
    name: "Enterprise",
    price: "Custom",
    period: "Contact Us",
    description:
      "Tailored solutions for schools, companies, and large organizations.",
    features: [
      "All Bramlo+ Annual features",
      "Team & user management",
      "SSO & API integration",
      "Bulk licensing & discounts",
      "Custom branding options",
      "Dedicated account manager",
      "Compliance support (GDPR, FERPA)",
    ],
    highlighted: false,
    action: "Contact Sales",
    // Keep internal route for Enterprise
    link: "/contact",
  },
];

const FEATURES = [
  {
    icon: <Zap size={24} />,
    title: "AI-Powered Learning",
    description: "Smart algorithms that adapt to your learning style and pace.",
  },
  {
    icon: <Clock size={24} />,
    title: "Spaced Repetition",
    description: "Scientifically proven system to improve long-term retention.",
  },
  {
    icon: <Book size={24} />,
    title: "Comprehensive Library",
    description: "Extensive study materials and resources at your fingertips.",
  },
  {
    icon: <MessageSquare size={24} />,
    title: "Priority Support",
    description: "Get help when you need it with our dedicated support team.",
  },
  {
    icon: <Shield size={24} />,
    title: "Enterprise Security",
    description: "Bank-grade security to protect your data and privacy.",
  },
];

const FAQ = [
  {
    question: "How do the quiz limits work?",
    answer:
      "Starter plan includes 10-15 quizzes per month. Pro and above get unlimited quizzes.",
  },
  {
    question: "Can I switch between plans?",
    answer:
      "Yes! You can upgrade or downgrade at any time. Changes take effect on your next billing cycle.",
  },
  {
    question: "What's the difference between Pro and Annual Pro?",
    answer:
      "Annual Pro includes all Pro features plus exclusive perks like webinars and extended analytics, with 25% savings.",
  },
  {
    question: "What payment methods do you accept?",
    answer:
      "We accept all major credit cards, PayPal, and offer special payment terms for Enterprise clients.",
  },
];

const Pricing = () => {
  const handleIntersection = useCallback((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(styles.inView);
        entry.target.observer?.unobserve(entry.target);
      }
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
      rootMargin: "50px",
    });

    const elements = document.querySelectorAll("[data-animate]");
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, [handleIntersection]);

  return (
    <div className={styles.pricingContainer}>
      <div className={styles.content}>
        <Link to="/" className={styles.backButton}>
          <ArrowLeft size={18} />
          Back
        </Link>

        <header className={styles.header}>
          <div className={styles.titleWrapper} data-animate>
            <h1 className={styles.title}>Simple, Transparent Pricing</h1>
          </div>
          <p className={styles.subtitle} data-animate>
            Choose the perfect plan for your learning journey. All plans include
            core features and updates.
          </p>
        </header>

        <section className={styles.section}>
          <div className={styles.plansGrid}>
            {PRICING_PLANS.map((plan, index) => (
              plan.link.startsWith("http") ? (
                <a
                  key={plan.name}
                  href={plan.link}
                  className={`${styles.planCard} ${
                    plan.highlighted ? styles.highlighted : ''
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-animate
                  style={{ animationDelay: `${0.1 * (index + 2)}s` }}
                >
                  <h3 className={styles.planName}>{plan.name}</h3>
                  <div className={styles.planPrice}>
                    <span className={styles.price}>{plan.price}</span>
                    {plan.period && (
                      <span className={styles.period}>{plan.period}</span>
                    )}
                  </div>
                  {plan.annualNote && (
                    <div className={styles.annualNote}>{plan.annualNote}</div>
                  )}
                  <ul className={styles.planFeatures}>
                    {plan.features.map((feature) => (
                      <li key={feature} className={styles.planFeature}>
                        <Check size={16} />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <div className={styles.planAction}>
                    {plan.action}
                  </div>
                </a>
              ) : (
                <Link
                  key={plan.name}
                  to={plan.link}
                  className={`${styles.planCard} ${
                    plan.highlighted ? styles.highlighted : ''
                  }`}
                  data-animate
                  style={{ animationDelay: `${0.1 * (index + 2)}s` }}
                >
                  <h3 className={styles.planName}>{plan.name}</h3>
                  <div className={styles.planPrice}>
                    <span className={styles.price}>{plan.price}</span>
                    {plan.period && (
                      <span className={styles.period}>{plan.period}</span>
                    )}
                  </div>
                  {plan.annualNote && (
                    <div className={styles.annualNote}>{plan.annualNote}</div>
                  )}
                  <ul className={styles.planFeatures}>
                    {plan.features.map((feature) => (
                      <li key={feature} className={styles.planFeature}>
                        <Check size={16} />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <div className={styles.planAction}>
                    {plan.action}
                  </div>
                </Link>
              )
            ))}
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle} data-animate>
            Key Features
          </h2>
          <div className={styles.featuresGrid}>
            {FEATURES.map((feature, index) => (
              <div
                key={feature.title}
                className={styles.featureCard}
                data-animate
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <div className={styles.featureIcon}>{feature.icon}</div>
                <h3 className={styles.featureTitle}>{feature.title}</h3>
                <p className={styles.featureDescription}>
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </section>

        <section className={`${styles.section} ${styles.universitiesSection}`}>
          <h2 className={styles.sectionTitle} data-animate>
            Used by Students At
          </h2>
          <div className={styles.universitiesCarouselContainer}>
            <div className={styles.universitiesCarousel}>
              {[
                "Stanford",
                "MIT",
                "Harvard",
                "Waterloo",
                "Caltech",
                "Berkeley",
                "Princeton",
                "Yale",
                "Oxford",
                "Cambridge",
                "ETH Zürich",
                "Imperial",
                "Toronto",
                "McGill",
                "UBC",
                "Carnegie Mellon",
                "Columbia",
                "UCLA",
                "Georgia Tech",
                "EPFL",
              ].map((uni, index) => (
                <div key={`${uni}-${index}`} className={styles.universityItem}>
                  <h3>{uni}</h3>
                </div>
              ))}
              {[
                "Stanford",
                "MIT",
                "Harvard",
                "Waterloo",
                "Caltech",
                "Berkeley",
                "Princeton",
                "Yale",
                "Oxford",
                "Cambridge",
                "ETH Zürich",
                "Imperial",
                "Toronto",
                "McGill",
                "UBC",
                "Carnegie Mellon",
                "Columbia",
                "UCLA",
                "Georgia Tech",
                "EPFL",
              ].map((uni, index) => (
                <div
                  key={`${uni}-${index}-duplicate`}
                  className={styles.universityItem}
                >
                  <h3>{uni}</h3>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle} data-animate>
            Frequently Asked Questions
          </h2>
          <div className={styles.faqGrid}>
            {FAQ.map((item, index) => (
              <div
                key={item.question}
                className={styles.faqCard}
                data-animate
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <div className={styles.faqIcon}>
                  <HelpCircle size={20} />
                </div>
                <h3 className={styles.faqQuestion}>{item.question}</h3>
                <p className={styles.faqAnswer}>{item.answer}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Pricing;
