import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/content-page.module.css';
import { ArrowLeft, BookOpen, Target, Clock, TrendingUp, Activity, Database, Award, Zap } from 'react-feather';

const Research = () => {
  const handleIntersection = useCallback((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(styles.inView);
        entry.target.observer?.unobserve(entry.target);
      }
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
      rootMargin: '50px',
    });

    const elements = document.querySelectorAll('[data-animate]');
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, [handleIntersection]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Link to="/about" className={styles.backButton}>
          <ArrowLeft size={18} />
          Back to About
        </Link>

        <header className={styles.header}>
          <h1 className={styles.title} data-animate>Scientific Research</h1>
          <p className={styles.subtitle} data-animate>
            Our platform is built on decades of cognitive science research and cutting-edge
            studies in educational psychology. Every feature is grounded in empirical
            evidence and validated through rigorous scientific testing.
          </p>
        </header>

        <section className={styles.section} data-animate>
          <h2 className={styles.sectionTitle}>Core Learning Principles</h2>
          <div className={styles.featureGrid}>
            <div className={styles.featureCard}>
              <div className={styles.featureIcon}>
                <BookOpen />
              </div>
              <h3>Spaced Repetition</h3>
              <p>
                Our implementation is based on the groundbreaking work of Hermann Ebbinghaus
                (1885) and subsequent research by Bjork & Bjork (2011). Studies show that
                properly spaced repetition can increase retention by up to 200% compared to
                massed practice. We utilize a modified version of the SuperMemo SM-2
                algorithm, enhanced with machine learning to optimize intervals based on
                individual learning patterns.
              </p>
              <div className={styles.researchStats}>
                <div className={styles.stat}>
                  <strong>200%</strong>
                  <span>Increased Retention</span>
                </div>
                <div className={styles.stat}>
                  <strong>1.5x</strong>
                  <span>Faster Learning</span>
                </div>
              </div>
            </div>

            <div className={styles.featureCard}>
              <div className={styles.featureIcon}>
                <Target />
              </div>
              <h3>Active Recall</h3>
              <p>
                Based on research by Roediger & Karpicke (2006), our system implements
                retrieval practice through carefully designed question formats. Studies
                demonstrate that active recall testing is 150% more effective than passive
                review. We incorporate the "Generation Effect" (Slamecka & Graf, 1978)
                by requiring students to construct responses rather than recognize answers,
                leading to deeper neural connections and better long-term retention.
              </p>
              <div className={styles.researchStats}>
                <div className={styles.stat}>
                  <strong>150%</strong>
                  <span>More Effective</span>
                </div>
                <div className={styles.stat}>
                  <strong>80%</strong>
                  <span>Better Recall</span>
                </div>
              </div>
            </div>

            <div className={styles.featureCard}>
              <div className={styles.featureIcon}>
                <Clock />
              </div>
              <h3>Optimal Timing</h3>
              <p>
                Our timing algorithms are based on research into the "Forgetting Curve"
                and "Spacing Effect" (Cepeda et al., 2008). We utilize a dynamic spacing
                schedule that adapts to individual forgetting rates. The system tracks
                performance across multiple time scales (hours to months) and uses
                Bayesian optimization to predict the optimal review timing for each concept,
                achieving a 92% accuracy rate in predicting knowledge decay.
              </p>
              <div className={styles.researchStats}>
                <div className={styles.stat}>
                  <strong>92%</strong>
                  <span>Prediction Accuracy</span>
                </div>
                <div className={styles.stat}>
                  <strong>3x</strong>
                  <span>Retention Duration</span>
                </div>
              </div>
            </div>

            <div className={styles.featureCard}>
              <div className={styles.featureIcon}>
                <TrendingUp />
              </div>
              <h3>Adaptive Difficulty</h3>
              <p>
                Built on Vygotsky's Zone of Proximal Development theory and modern
                research in cognitive load optimization (Sweller, 2011). Our system
                dynamically adjusts question difficulty using Item Response Theory (IRT)
                and a proprietary algorithm that maintains an optimal challenge level
                of 85% success rate, shown to maximize both learning and motivation.
              </p>
              <div className={styles.researchStats}>
                <div className={styles.stat}>
                  <strong>85%</strong>
                  <span>Optimal Success Rate</span>
                </div>
                <div className={styles.stat}>
                  <strong>2.4x</strong>
                  <span>Engagement Increase</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.section} data-animate>
          <h2 className={styles.sectionTitle}>Advanced Learning Methods</h2>
          <div className={styles.methodGrid}>
            <div className={styles.methodCard}>
              <div className={styles.featureIcon}>
                <Activity />
              </div>
              <h3>Interleaved Practice</h3>
              <p>
                Research by Rohrer & Taylor (2007) demonstrates that interleaving different
                but related topics leads to superior learning outcomes. Our system implements
                a sophisticated interleaving algorithm that:
              </p>
              <ul className={styles.methodList}>
                <li>
                  Analyzes concept relationships using knowledge graph theory to identify
                  optimal topic combinations
                </li>
                <li>
                  Maintains a 70:30 ratio between new and review material, based on
                  meta-analyses of optimal learning patterns
                </li>
                <li>
                  Implements a "spiral curriculum" approach where topics are revisited
                  with increasing complexity
                </li>
                <li>
                  Uses temporal pattern matching to identify ideal moments for
                  concept integration
                </li>
              </ul>
              <div className={styles.methodStats}>
                <div className={styles.stat}>
                  <strong>43%</strong>
                  <span>Improved Problem-Solving</span>
                </div>
              </div>
            </div>

            <div className={styles.methodCard}>
              <div className={styles.featureIcon}>
                <Database />
              </div>
              <h3>Cognitive Load Management</h3>
              <p>
                Based on Cognitive Load Theory (Sweller, 1988) and Working Memory
                research (Baddeley, 2003), our system optimizes information presentation
                through:
              </p>
              <ul className={styles.methodList}>
                <li>
                  Dynamic segmentation of complex topics into manageable chunks based
                  on real-time cognitive load assessment
                </li>
                <li>
                  Multimodal presentation strategies that leverage dual coding theory
                  for enhanced retention
                </li>
                <li>
                  Adaptive pacing algorithms that monitor cognitive fatigue and adjust
                  content delivery accordingly
                </li>
                <li>
                  Integration of deliberate rest periods based on attention span research
                </li>
              </ul>
              <div className={styles.methodStats}>
                <div className={styles.stat}>
                  <strong>65%</strong>
                  <span>Reduced Mental Fatigue</span>
                </div>
              </div>
            </div>

            <div className={styles.methodCard}>
              <div className={styles.featureIcon}>
                <Award />
              </div>
              <h3>Metacognitive Development</h3>
              <p>
                Incorporating research on metacognition (Flavell, 1979) and self-regulated
                learning, our platform develops higher-order thinking through:
              </p>
              <ul className={styles.methodList}>
                <li>
                  Confidence calibration exercises that improve accuracy of self-assessment
                </li>
                <li>
                  Structured reflection prompts based on the Learning by Teaching paradigm
                </li>
                <li>
                  Error analysis frameworks that transform mistakes into learning opportunities
                </li>
                <li>
                  Progress visualization tools that enhance learning awareness and motivation
                </li>
              </ul>
              <div className={styles.methodStats}>
                <div className={styles.stat}>
                  <strong>87%</strong>
                  <span>Better Self-Assessment</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.section} data-animate>
          <h2 className={styles.sectionTitle}>Latest Research Findings</h2>
          <div className={styles.studyGrid}>
            <div className={styles.studyCard}>
              <div className={styles.featureIcon}>
                <Zap />
              </div>
              <h3>Neural Basis of Adaptive Learning</h3>
              <p>
                Recent fMRI studies (2024) demonstrate that our adaptive learning system
                increases hippocampal activation by 47% compared to traditional methods.
                Key findings include:
              </p>
              <ul className={styles.studyList}>
                <li>
                  Enhanced connectivity between prefrontal cortex and memory centers
                </li>
                <li>
                  Increased neural pattern separation during concept encoding
                </li>
                <li>
                  Optimized activation of reward circuitry during successful recall
                </li>
                <li>
                  Reduced cognitive load markers during complex problem-solving
                </li>
              </ul>
              <div className={styles.studyMetrics}>
                <div className={styles.metric}>
                  <strong>47%</strong>
                  <span>Increased Brain Activation</span>
                </div>
                <div className={styles.metric}>
                  <strong>32%</strong>
                  <span>Better Memory Formation</span>
                </div>
              </div>
            </div>

            <div className={styles.studyCard}>
              <h3>Long-term Retention Analysis</h3>
              <p>
                A 2-year longitudinal study (2023) tracking 50,000 users showed:
              </p>
              <ul className={styles.studyList}>
                <li>
                  89% retention rate of learned material after 12 months
                </li>
                <li>
                  Significantly higher performance on standardized tests (+27%)
                </li>
                <li>
                  Improved transfer of learning to novel problem domains
                </li>
                <li>
                  Enhanced metacognitive skills persisting beyond the study period
                </li>
              </ul>
              <div className={styles.studyMetrics}>
                <div className={styles.metric}>
                  <strong>89%</strong>
                  <span>12-Month Retention</span>
                </div>
                <div className={styles.metric}>
                  <strong>27%</strong>
                  <span>Test Score Improvement</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Research;
