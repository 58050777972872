import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/content-page.module.css';
import { ArrowLeft, RefreshCw, GitBranch, Code, CheckCircle } from 'react-feather';

const Process = () => {
  const handleIntersection = useCallback((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(styles.inView);
        entry.target.observer?.unobserve(entry.target);
      }
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
      rootMargin: '50px',
    });

    const elements = document.querySelectorAll('[data-animate]');
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, [handleIntersection]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Link to="/about" className={styles.backButton}>
          <ArrowLeft size={18} />
          Back to About
        </Link>

        <header className={styles.header}>
          <h1 className={styles.title} data-animate>Our Process</h1>
          <p className={styles.subtitle} data-animate>
            Learn about our continuous development process and how we constantly
            evolve to provide the best learning experience.
          </p>
        </header>

        <section className={styles.section} data-animate>
          <h2 className={styles.sectionTitle}>Development Cycle</h2>
          <div className={styles.cycleGrid}>
            <div className={styles.cycleCard}>
              <div className={styles.cycleIcon}>
                <RefreshCw />
              </div>
              <h3>Continuous Integration</h3>
              <p>
                Daily updates and improvements based on user feedback and performance
                metrics, ensuring our platform stays cutting-edge.
              </p>
            </div>

            <div className={styles.cycleCard}>
              <div className={styles.cycleIcon}>
                <GitBranch />
              </div>
              <h3>Feature Development</h3>
              <p>
                Parallel development of new features and improvements, with rigorous
                testing before integration.
              </p>
            </div>

            <div className={styles.cycleCard}>
              <div className={styles.cycleIcon}>
                <Code />
              </div>
              <h3>Code Quality</h3>
              <p>
                Strict code review process and automated testing to maintain high
                quality and reliability standards.
              </p>
            </div>

            <div className={styles.cycleCard}>
              <div className={styles.cycleIcon}>
                <CheckCircle />
              </div>
              <h3>User Validation</h3>
              <p>
                Beta testing with real users to validate new features and gather
                feedback before full release.
              </p>
            </div>
          </div>
        </section>

        <section className={styles.section} data-animate>
          <h2 className={styles.sectionTitle}>Platform Evolution</h2>
          <div className={styles.timeline}>
            <div className={styles.timelineItem}>
              <h3>Data Collection</h3>
              <p>
                We continuously gather data on user interactions, learning patterns,
                and performance metrics.
              </p>
            </div>

            <div className={styles.timelineItem}>
              <h3>Analysis</h3>
              <p>
                Our data science team analyzes this information to identify areas
                for improvement and optimization.
              </p>
            </div>

            <div className={styles.timelineItem}>
              <h3>Implementation</h3>
              <p>
                Development teams implement improvements and new features based on
                data-driven insights.
              </p>
            </div>

            <div className={styles.timelineItem}>
              <h3>Validation</h3>
              <p>
                Rigorous testing and user feedback ensure that changes positively
                impact the learning experience.
              </p>
            </div>
          </div>
        </section>

        <section className={styles.section} data-animate>
          <h2 className={styles.sectionTitle}>Recent Updates</h2>
          <div className={styles.updateList}>
            <div className={styles.updateItem}>
              <h3>AI Model Improvements</h3>
              <p>
                Enhanced neural networks for better learning pattern recognition and
                personalization.
              </p>
              <span className={styles.date}>Last week</span>
            </div>

            <div className={styles.updateItem}>
              <h3>Performance Optimization</h3>
              <p>
                System-wide performance improvements for faster response times and
                better user experience.
              </p>
              <span className={styles.date}>2 weeks ago</span>
            </div>

            <div className={styles.updateItem}>
              <h3>New Learning Algorithms</h3>
              <p>
                Implemented advanced spaced repetition algorithms based on latest
                research.
              </p>
              <span className={styles.date}>Last month</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Process;
