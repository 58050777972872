import React, { useState, useRef } from "react";
import { FiUpload, FiEdit, FiFile, FiX, FiSend } from "react-icons/fi";
import styles from "../styles/home.module.css";
import { useAuth } from "../context/AuthContext";

const ContentInput = ({ onTextChange, onFileChange, onSubmit, feature }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [inputMethod, setInputMethod] = useState("text");
  const [textContent, setTextContent] = useState("");
  const fileInputRef = useRef(null);
  const [numQuestions, setNumQuestions] = useState(3);
  const { isUserPremium } = useAuth();

  const maxQuestions = isUserPremium ? 10 : 5;

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check file type
    const allowedTypes = [
      "application/pdf",
      "text/plain",
      "application/msword",
    ];
    if (!allowedTypes.includes(file.type)) {
      alert("Please upload a PDF, TXT, or DOC file");
      return;
    }

    // Check file size (10MB limit)
    if (file.size > 10 * 1024 * 1024) {
      alert("File size should be less than 10MB");
      return;
    }

    setSelectedFile(file);
    setInputMethod("file");
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setInputMethod("text");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = async () => {
    if (inputMethod === "text" && !textContent.trim()) {
      alert("Please enter some content first");
      return;
    }

    if (inputMethod === "file" && !selectedFile) {
      alert("Please select a file first");
      return;
    }

    try {
      if (inputMethod === "file") {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("num_questions", numQuestions.toString());
        await onFileChange({ file: selectedFile, numQuestions });
      } else {
        await onSubmit({ text: textContent, numQuestions });
      }
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  return (
    <div className={styles.contentInput}>
      <div className={styles.inputToggle}>
        <button
          className={`${styles.toggleButton} ${
            inputMethod === "text" ? styles.active : ""
          }`}
          onClick={() => setInputMethod("text")}
        >
          <FiEdit /> Text Input
        </button>
        <button
          className={`${styles.toggleButton} ${
            inputMethod === "file" ? styles.active : ""
          }`}
          onClick={() => setInputMethod("file")}
        >
          <FiUpload /> File Upload
        </button>
      </div>

      <div className={styles.quizOptions}>
        <label className={styles.quizOptionLabel}>
          Number of Questions:
          <div className={styles.questionCounter}>
            <button
              onClick={() => setNumQuestions(Math.max(1, numQuestions - 1))}
              className={styles.counterButton}
              disabled={numQuestions <= 1}
            >
              -
            </button>
            <span>{numQuestions}</span>
            <button
              onClick={() =>
                setNumQuestions(Math.min(maxQuestions, numQuestions + 1))
              }
              className={styles.counterButton}
              disabled={numQuestions >= maxQuestions}
            >
              +
            </button>
          </div>
          {!isUserPremium && numQuestions >= 5 && (
            <span className={styles.premiumHint}>
              Upgrade to premium for up to 25 questions
            </span>
          )}
        </label>
      </div>

      {inputMethod === "text" ? (
        <textarea
          className={styles.textarea}
          onChange={(e) => {
            setTextContent(e.target.value);
            onTextChange(e.target.value);
          }}
          placeholder={`Enter your content here to generate ${feature.title.toLowerCase()}...`}
          value={textContent}
        />
      ) : (
        <div className={styles.fileUploadArea}>
          {selectedFile ? (
            <div className={styles.selectedFile}>
              <div className={styles.fileInfo}>
                <FiFile />
                <span>{selectedFile.name}</span>
                <span className={styles.fileSize}>
                  ({(selectedFile.size / 1024 / 1024).toFixed(2)} MB)
                </span>
              </div>
              <button className={styles.removeFile} onClick={handleRemoveFile}>
                <FiX />
              </button>
            </div>
          ) : (
            <>
              <input
                ref={fileInputRef}
                type="file"
                accept=".pdf,.txt,.doc,.docx"
                onChange={handleFileChange}
                id="file-upload"
                className={styles.fileInput}
              />
              <label htmlFor="file-upload" className={styles.fileLabel}>
                <FiUpload />
                <span>Choose a file or drag it here</span>
                <span className={styles.supportedFormats}>
                  Supported formats: PDF, TXT, DOC
                </span>
              </label>
            </>
          )}
        </div>
      )}

      <div className={styles.submitSection}>
        <button className={styles.submitButton} onClick={handleSubmit}>
          <FiSend /> Generate {feature.title}
        </button>
      </div>
    </div>
  );
};

export default ContentInput;
