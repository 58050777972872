import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/contact.module.css";
import {
  ArrowRight,
  ArrowLeft,
  Mail,
  MessageCircle,
  GitHub,
  Linkedin,
  Twitter,
} from "react-feather";

const CONTACT_METHODS = [
  {
    title: "Email",
    description:
      "Drop us a line anytime. We strive to respond within 24 hours.",
    icon: <Mail size={24} />,
    action: "Send Email",
    link: "mailto:contact@quizmate.ai",
  },
  {
    title: "Chat",
    description:
      "Get instant support through our live chat during business hours.",
    icon: <MessageCircle size={24} />,
    action: "Under Maintenance.. Coming back soon!",
    // link: "#chat",
  },
];

const SOCIAL_LINKS = [
  {
    platform: "LinkedIn",
    description: "Connect with our team and stay updated on company news.",
    icon: <Linkedin size={24} />,
    link: "https://www.linkedin.com/company/bramlo/",
  },
  {
    title: "Twitter",
    description:
      "Follow us for the latest updates, tips, and educational insights.",
    icon: <Twitter size={24} />,
    link: "https://twitter.com/bramlo",
  },
];

const Contact = () => {
  const handleIntersection = useCallback((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(styles.inView);
        entry.target.observer?.unobserve(entry.target);
      }
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
      rootMargin: "50px",
    });

    const elements = document.querySelectorAll("[data-animate]");
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, [handleIntersection]);

  return (
    <div className={styles.contactContainer}>
      <div className={styles.content}>
        <Link to="/" className={styles.backButton}>
          <ArrowLeft size={18} />
          Back
        </Link>

        <header className={styles.header}>
          <div className={styles.titleWrapper} data-animate>
            <h1 className={styles.title}>Get in Touch</h1>
          </div>
          <p className={styles.subtitle} data-animate>
            Have questions or feedback? We'd love to hear from you. Our team is
            here to help make your learning journey exceptional.
          </p>
        </header>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle} data-animate>
            Contact Methods
          </h2>
          <div className={styles.methodGrid}>
            {CONTACT_METHODS.map((method, index) => (
              <div
                key={method.title}
                className={styles.methodCard}
                data-animate
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <div className={styles.methodIcon}>{method.icon}</div>
                <h3 className={styles.methodTitle}>{method.title}</h3>
                <p className={styles.methodDescription}>{method.description}</p>
                <a href={method.link} className={styles.methodAction}>
                  {method.action}
                  {method.link && (
                    <ArrowRight size={16} className={styles.actionArrow} />
                  )}
                </a>
              </div>
            ))}
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle} data-animate>
            Connect With Us
          </h2>
          <div className={styles.socialGrid}>
            {SOCIAL_LINKS.map((social, index) => (
              <a
                key={social.platform}
                href={social.link}
                className={styles.socialCard}
                target="_blank"
                rel="noopener noreferrer"
                data-animate
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <div className={styles.socialIcon}>{social.icon}</div>
                <h3 className={styles.socialPlatform}>{social.platform}</h3>
                <p className={styles.socialDescription}>{social.description}</p>
              </a>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
