import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/landing.module.css";
import { FaArrowRight } from "react-icons/fa";
import Lottie from "lottie-react";
import globe from "../assets/img/globe-empty.json";
import bLogo from "../assets/img/theONEE1.png";
import ModernDemo from "../components/ModernDemo";
import {
  FaGraduationCap,
  FaBrain,
  FaRobot,
  FaMagic,
  FaChartLine,
  FaChevronRight,
  FaMicrophone,
  FaPencilAlt,
} from "react-icons/fa";

// Memoized data
const features = [
  {
    icon: <FaBrain />,
    title: "AI Study Assistant",
    description:
      "Get instant answers and personalized study recommendations powered by advanced AI",
  },
  {
    icon: <FaMicrophone />,
    title: "Voice to Notes",
    description:
      "Convert lectures and voice notes into structured study materials automatically",
  },
  {
    icon: <FaPencilAlt />,
    title: "Smart OCR",
    description:
      "Transform handwritten notes into digital format with our advanced recognition system",
  },
];

const capabilities = [
  {
    icon: <FaMagic />,
    title: "Smart Content Creation",
    description:
      "Automatically transform any academic material into optimized study resources",
    features: [
      "Smart quiz creation from lectures/notes/textbooks",
      "Adaptive flashcard system with retention scoring",
      "Key concept distillation from complex materials",
      "Custom practice sets tailored to your level",
    ],
  },
  {
    icon: <FaChartLine />,
    title: "Learning Intelligence",
    description:
      "Advanced analytics tracking knowledge acquisition and retention",
    features: [
      "Visual progress mapping across subjects",
      "Skill gap identification with targeted exercises",
      "Efficiency optimization for study sessions",
      "Predictive performance analysis",
    ],
  },
  {
    icon: <FaRobot />,
    title: "Expert Academic Support",
    description: "24/7 subject-specific guidance across disciplines",
    features: [
      "Instant concept clarification in any field",
      "Personalized study plan generation",
      "Multilingual support for global learners",
      "Cross-subject connections and real-world applications",
    ],
  },
];

const stats = [
  {
    value: "320K+",
    label: "Active Students",
    description: "Students experiencing personalized learning paths.",
  },
  {
    value: "85%",
    label: "Success Rate",
    description: "Average improvement in test scores.",
  },
  {
    value: "24/7",
    label: "AI Support",
    description: "Round-the-clock learning assistance.",
  },
];

const testimonials = [
  // University Students (80%)
  {
    quote:
      "Between juggling a part-time job and track practice, Bramlo’s flexible study schedules keep me on top of my assignments. I'm way more confident heading into midterms!",
    author: "Taylor Brown",
    title: "Psychology Student at Princeton",
    initial: "T",
  },
  {
    quote:
      "I used to worry about my accent in discussion sections, but Bramlo’s interactive modules have boosted my confidence to speak up in class.",
    author: "Daniela Reyes",
    title: "International Relations Student at Yale",
    initial: "D",
  },
  {
    quote:
      "As an engineering major, problem sets can be brutal. Bramlo’s step-by-step breakdowns help me grasp each concept, so I’m not just cramming the night before exams.",
    author: "Michael Chen",
    title: "Mechanical Engineering Student at MIT",
    initial: "M",
  },
  {
    quote:
      "Late-night study sessions are my reality. Bramlo’s 24/7 AI tutor is there when everyone else is asleep and I’m stuck on a tricky question.",
    author: "Emily Carter",
    title: "Physics Student at Caltech",
    initial: "E",
  },
  {
    quote:
      "I joined the campus coding club this semester, and Bramlo’s personalized practice sets have been a lifesaver. My teammates even started using it too!",
    author: "Brandon Lee",
    title: "Computer Science Student at Carnegie Mellon",
    initial: "B",
  },
  {
    quote:
      "Dorm life comes with constant distractions, but Bramlo’s study reminders keep me on track. I’ve never felt so prepared for exams!",
    author: "Rachel Davis",
    title: "Economics Student at Cambridge",
    initial: "R",
  },
  {
    quote:
      "I can’t afford a private tutor, so Bramlo’s AI guidance is the next best thing—except it's available whenever I need help.",
    author: "Jacob Wilson",
    title: "Mathematics Student at University of Toronto",
    initial: "J",
  },
  {
    quote:
      "From advanced grammar tips to clarifying historical contexts, Bramlo made my literature research so much easier. Now I can focus on enjoying campus life!",
    author: "Hannah DeWitt",
    title: "English Literature Student at Oxford",
    initial: "H",
  },

  // Non-Students (20%)
  {
    quote:
      "Running a small business leaves me little time for professional development. With Bramlo’s short lessons, I can learn crucial skills without neglecting work.",
    author: "Nina Martinez",
    title: "Small Business Owner",
    initial: "N",
  },
  {
    quote:
      "My teenager used to dread study time, but Bramlo’s gamified approach changed that. He’s actually excited to log in and learn every day.",
    author: "Robert Greene",
    title: "Homeschool Parent",
    initial: "R",
  },
];

const faq = [
  {
    question: "How does the AI tutor work?",
    answer:
      "Our AI tutor leverages advanced natural language processing and machine learning to understand your questions, learning style, and progress. It then generates detailed, personalized explanations, practice exercises, and real-time feedback to help you grasp concepts quickly and thoroughly.",
  },
  {
    question: "Can I use my own study materials?",
    answer:
      "Absolutely! You can upload lecture slides, PDFs, or other text-based content. Bramlo’s AI automatically converts these into interactive study guides, quizzes, and flashcards—so you can focus on learning rather than creating resources from scratch.",
  },
  {
    question: "Is Bramlo suitable for all subjects?",
    answer:
      "Yes! Whether you’re working through complex calculus problems or analyzing a classic novel, Bramlo adapts to the subject at hand. Our platform tailors practice questions and explanations to suit your specific coursework, ensuring an effective study experience regardless of topic or difficulty.",
  },
  {
    question: "How does the pricing work?",
    answer:
      "We offer flexible subscription plans starting at just $2.49/month. You can choose between monthly or annual billing. Check out our pricing page to find the plan that meets your needs.",
  },
];

const universities = [
  "Stanford",
  "MIT",
  "Harvard",
  "Waterloo",
  "Caltech",
  "Berkeley",
  "Princeton",
  "Yale",
  "Columbia",
  "Cornell",
  "Oxford",
  "Cambridge",
  "Imperial College London",
  "ETH Zürich",
  "UCLA",
  "Carnegie Mellon",
  "Georgia Tech",
  "University of Toronto",
  "McGill",
  "Duke",
];

const LandingPage = () => {
  const demoRef = useRef(null);
  const featuresRef = useRef(null);

  const scrollToDemo = () => {
    demoRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToFeatures = () => {
    featuresRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleIntersection = useRef((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(styles.inView);
        entry.target.observer?.unobserve(entry.target);
      }
    });
  });

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection.current, {
      threshold: 0.1,
      rootMargin: "50px",
    });

    const elements = document.querySelectorAll("[data-animate]");
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, [handleIntersection]);

  return (
    <div className={styles.aboutContainer}>
      <div className={styles.content}>
        <nav className={styles.navbar}>
          <div className={styles.navContent}>
            <Link to="/" className={styles.logo}>
              <img src={bLogo} alt="Bramlo" className={styles.logoIcon} />
            </Link>
            <div className={styles.navLinks}>
              <button onClick={scrollToFeatures} className={styles.navLink}>
                Features
              </button>
              <Link to="/pricing" className={styles.navLink}>
                Pricing
              </Link>
              <Link to="/about" className={styles.navLink}>
                About
              </Link>
              <Link to="/contact" className={styles.navLink}>
                Contact
              </Link>
              <Link to="/auth" className={styles.loginLink}>
                Log in
              </Link>
            </div>
          </div>
        </nav>

        <header className={styles.header}>
          <div className={styles.titleWrapper} data-animate>
            <h1 className={styles.title}>Bramlo</h1>
            <h1 className={styles.title1}>
              {/* Bramlo: Smart AI. Effortless Learning. */}
              Adapt Today, Lead Tomorrow.
            </h1>
            <p className={styles.subtitle}>
              Turn any file, note, or text into dynamic learning materials with
              Bramlo's advanced AI. Break free from information overload and
              learn faster—effortlessly.
            </p>
            <div className={styles.heroButtons}>
              <button onClick={scrollToDemo} className={styles.primaryButton}>
                Meet Lumi <FaArrowRight />
              </button>
              <Link to="/about" className={styles.secondaryButton}>
                Learn More <FaArrowRight />
              </Link>
            </div>
          </div>
          <div className={styles.heroAnimation} data-animate>
            <Lottie
              animationData={globe}
              loop={true}
              style={{ width: 400, height: 400 }}
            />
          </div>
        </header>

        <section ref={featuresRef} className={styles.section}>
          <h2 className={styles.sectionTitle} data-animate>
            AI-Powered Features
          </h2>
          <p className={styles.sectionSubtitle} data-animate>
            Experience the future of learning with our advanced AI-driven study
            tools
          </p>
          <div className={styles.valueGrid}>
            {features.map((feature, index) => (
              <div
                key={feature.title}
                className={styles.valueCard}
                data-animate
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <div className={styles.valueIcon}>{feature.icon}</div>
                <h3 className={styles.valueTitle}>{feature.title}</h3>
                <p className={styles.valueDescription}>{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle} data-animate>
            Powerful Capabilities
          </h2>
          <div className={styles.valueGrid}>
            {capabilities.map((capability, index) => (
              <div
                key={capability.title}
                className={styles.valueCard}
                data-animate
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <div className={styles.valueIcon}>{capability.icon}</div>
                <h3 className={styles.valueTitle}>{capability.title}</h3>
                <p className={styles.valueDescription}>
                  {capability.description}
                </p>
                <ul className={styles.featuresList}>
                  {capability.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        <section ref={demoRef} className={styles.section}>
          <div className={styles.demoSection}>
            <div className={styles.demoContent}>
              <h2 className={styles.sectionTitle} data-animate>
                Interactive AI Learning Assistant
              </h2>
              <p className={styles.subtitle}>
                Experience Lumi, your personal AI tutor. Ask questions, get
                instant explanations, and transform any content into effective
                study materials.
              </p>
            </div>
            <div className={styles.demoPreview}>
              <ModernDemo />
            </div>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle} data-animate>
            Student Success Stories
          </h2>
          <div className={styles.valueGrid}>
            {stats.map((stat, index) => (
              <div
                key={stat.label}
                className={styles.valueCard}
                data-animate
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <div className={styles.statValue}>{stat.value}</div>
                <div className={styles.statLabel}>{stat.label}</div>
                <p className={styles.valueDescription}>{stat.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className={`${styles.section} ${styles.impactSection}`}>
          <h2 className={styles.sectionTitle} data-animate>
            What Students Say
          </h2>

          <div className={styles.impactCarouselContainer}>
            <div className={styles.impactCarousel}>
              {[...testimonials, ...testimonials, ...testimonials].map(
                (testimonial, index) => (
                  <div
                    key={`${testimonial.author}-${index}`}
                    className={styles.impactCard}
                  >
                    <div className={styles.testimonialQuote}>
                      {testimonial.quote}
                    </div>
                    <div className={styles.testimonialAuthor}>
                      {testimonial.author}
                    </div>
                    <div className={styles.testimonialTitle}>
                      {testimonial.title}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle} data-animate>
            Frequently Asked Questions
          </h2>
          <div className={`${styles.valueGrid} ${styles.faqGrid}`}>
            {faq.map((item, index) => (
              <div
                key={index}
                className={`${styles.valueCard} ${styles.faqCard}`}
                data-animate
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <h3 className={styles.faqQuestion}>{item.question}</h3>
                <p className={styles.faqAnswer}>{item.answer}</p>
              </div>
            ))}
          </div>
        </section>

        <section className={styles.heroSection}>
          <div className={styles.heroContent}>
            <h1 className={styles.heroTitle}>Grow more with Bramlo+</h1>
            <p className={styles.heroDescription}>
              More features. Unlimited resources. Powerful learning tools.
              Time-saving solutions. And more.
            </p>

            <div className={styles.pricingTiers}>
              {/* BASIC Plan */}
              <a
                href="https://buy.stripe.com/eVa9Bf4Km3D21bi000"
                className={styles.pricingTier}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={styles.tierName}>Basic</span>
                <div className={styles.tierPrice}>
                  <span className={styles.price}>$2.49</span>/month
                </div>
                <span className={styles.tierDescription}>
                  Perfect for getting started
                </span>
                <div className={styles.planAction}>Start Learning</div>
              </a>

              {/* Bramlo+ (Monthly) */}
              <a
                href="https://buy.stripe.com/28o8xb5Oqb5u07eeUV"
                className={styles.pricingTier}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={styles.tierName}>Bramlo+</span>
                <div className={styles.tierPrice}>
                  <span className={styles.price}>$9.99</span>/month
                </div>
                <span className={styles.tierDescription}>
                  Unlock unlimited potential
                </span>
                <div className={styles.planAction}>Go Pro</div>
              </a>

              {/* Bramlo+ Annual (Highlighted) */}
              <a
                href="https://buy.stripe.com/8wM14J1yaa1q5ryaEG"
                className={`${styles.pricingTier} ${styles.highlighted}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.saveBadge}>SAVE 30%</div>
                <span className={styles.tierName}>Bramlo+ Annual</span>
                <div className={styles.tierPrice}>
                  <span className={styles.price}>$6.99</span>/month
                </div>
                <span className={styles.annualNote}>
                  billed annually at $83.88/year
                </span>
                <span className={styles.tierDescription}>
                  Best value for dedicated learners
                </span>
                <div className={styles.planAction}>Best Value</div>
              </a>

              {/* Enterprise Plan */}
              <Link to="/contact" className={styles.pricingTier}>
                <span className={styles.tierName}>Enterprise</span>
                <div className={styles.tierPrice}>
                  <span className={styles.price}>Custom</span>
                  <span className={styles.period}></span>
                </div>
                <span className={styles.tierDescription}>
                  Tailored for organizations
                </span>
                <div className={styles.planAction}>Contact Sales</div>
              </Link>
            </div>

            <div className={styles.ctaButtons}>
              <Link to="/pricing" className={styles.primaryButton}>
                View All Plans <FaArrowRight />
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LandingPage;
