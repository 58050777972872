import React, { useState, useCallback, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import {
  FiUpload,
  FiMic,
  FiEdit,
  FiBook,
  FiClock,
  FiAward,
  FiSun,
  FiMoon,
  FiSettings,
  FiTrendingUp,
  FiPieChart,
  FiActivity,
  FiBell,
  FiSearch,
  FiLock,
  FiZap,
  FiTarget,
  FiBarChart2,
  FiGitBranch,
  FiFolder,
  FiMessageCircle,
  FiX,
  FiMaximize2,
  FiMinimize2,
  FiSend,
  FiFile,
  FiArrowRight,
} from "react-icons/fi";
import { useTheme } from "../context/ThemeContext";
import { useAuth } from "../context/AuthContext";
import styles from "../styles/home.module.css";
import Modal from "./Modal";
import ContentInput from "./ContentInput";
import ThemeToggle from "./ThemeToggle";
import bLogo from "../assets/img/theONEE1.png";
import { useSubscription } from "../hooks/useSubscription";
import "../styles/theme.css";
import QuizGenerator from "./QuizGenerator"; // Import QuizGenerator component

const API_BASE_URL = "http://localhost:5001";

const features = [
  {
    id: "content-input",
    icon: <FiEdit />,
    title: "Content Input",
    description: "Input or upload your study content",
    metrics: { processed: "10K+", formats: "Multiple" },
    isPremium: false,
  },
  {
    id: "flashcard-generation",
    icon: <FiClock />,
    title: "Flashcard Generation",
    description: "Create intelligent flashcards from any study material",
    metrics: { sets: "25K+", retention: "92%" },
    isPremium: false,
    endpoint: "/api/generate/flashcards",
    apiParams: {
      text: "",
      num_cards: 10,
    },
  },
  {
    id: "study-guide",
    icon: <FiBook />,
    title: "Study Guide Creator",
    description: "Generate comprehensive study guides with practice problems",
    metrics: { generated: "50K+", accuracy: "95%" },
    isPremium: false,
    endpoint: "/api/generate/study-guide",
    apiParams: {
      text: "",
    },
  },
  {
    id: "quiz-generation",
    icon: <FiTarget />,
    title: "Quiz Generation",
    description: "Create targeted quizzes to test your knowledge",
    metrics: { questions: "100K+", accuracy: "94%" },
    isPremium: false,
    endpoint: "/api/generate/multiple-choice",
    apiParams: {
      text: "",
      num_questions: 5,
    },
  },
  {
    id: "chat-tutor",
    icon: <FiMessageCircle />,
    title: "AI Tutor Chat",
    description: "Get instant help with concepts and problems",
    metrics: { sessions: "50K+", satisfaction: "96%" },
    isPremium: false,
    endpoint: "/api/chat",
    apiParams: {
      message: "",
      context: {},
    },
  },
  {
    id: "summary-generation",
    icon: <FiTrendingUp />,
    title: "Summary Generation",
    description: "Get concise summaries of your content",
    metrics: { summaries: "30K+", accuracy: "98%" },
    isPremium: false,
  },
  {
    id: "voice-notes",
    icon: <FiMic />,
    title: "Voice Notes",
    description: "Convert lectures into structured content",
    metrics: { hours: "150+", languages: "12" },
    isPremium: true,
  },
  {
    id: "advanced-analytics",
    icon: <FiActivity />,
    title: "Advanced Analytics",
    description: "In-depth learning analytics and insights",
    metrics: { tracked: "100K+", improvement: "45%" },
    isPremium: true,
  },
  {
    id: "ai-tutor",
    icon: <FiTarget />,
    title: "AI Tutor",
    description: "Personalized AI-powered tutoring sessions",
    metrics: { sessions: "50K+", satisfaction: "96%" },
    isPremium: true,
  },
  {
    id: "concept-mapping",
    icon: <FiGitBranch />,
    title: "Concept Mapping",
    description: "Visual learning path generation",
    metrics: { maps: "20K+", connections: "1M+" },
    isPremium: true,
  },
  {
    id: "performance-prediction",
    icon: <FiBarChart2 />,
    title: "Performance Prediction",
    description: "AI-driven study success forecasting",
    metrics: { accuracy: "94%", insights: "30K+" },
    isPremium: true,
  },
  {
    id: "study-optimization",
    icon: <FiZap />,
    title: "Study Optimization",
    description: "Personalized learning schedule optimization",
    metrics: { efficiency: "+40%", plans: "25K+" },
    isPremium: true,
  },
];

const recentActivity = [
  {
    id: 1,
    icon: <FiTrendingUp />,
    title: "Performance Analysis Updated",
    time: "2 hours ago",
    detail: "Physics Mechanics: 92% comprehension rate",
  },
  {
    id: 2,
    icon: <FiPieChart />,
    title: "Study Pattern Analysis",
    time: "5 hours ago",
    detail: "Optimal study time identified: 9:00 AM - 11:00 AM",
  },
  {
    id: 3,
    icon: <FiActivity />,
    title: "Learning Progress Report",
    time: "Yesterday",
    detail: "Weekly improvement: +15% in Biology",
  },
];

const HomePage = () => {
  const { isDarkMode, toggleTheme } = useTheme();
  const { user } = useAuth();
  const [activeModal, setActiveModal] = useState(null);
  const [isUserPremium] = useState(false);
  const [loading, setLoading] = useState({});
  const [error, setError] = useState({});
  const [activeContent, setActiveContent] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const contentRef = useRef(null);

  // Define closeModal first
  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, []);

  const handleFeatureClick = useCallback(
    async (feature) => {
      if (feature.isPremium && !isUserPremium) {
        // Show premium upgrade modal
        return;
      }

      setActiveModal(feature.id);
    },
    [isUserPremium]
  );

  const handleFeatureAction = useCallback(
    async (feature, data) => {
      setLoading((prev) => ({ ...prev, [feature.id]: true }));
      setError((prev) => ({ ...prev, [feature.id]: null }));

      try {
        let endpoint = `${API_BASE_URL}${feature.endpoint}`;
        let response;

        if (data.file) {
          const formData = new FormData();
          formData.append("file", data.file);

          if (feature.id === "quiz-generation") {
            formData.append("num_questions", data.numQuestions || 5);
          }

          response = await fetch(endpoint, {
            method: "POST",
            body: formData,
          });
        } else {
          response = await fetch(endpoint, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              text: data.text,
              num_questions: data.numQuestions,
              ...feature.apiParams,
            }),
          });
        }

        const result = await response.json();

        if (!response.ok || !result.success) {
          throw new Error(result.error || "Failed to process content");
        }

        handleResponse(feature.id, result);
        closeModal();
      } catch (err) {
        console.error("Error details:", err);
        setError((prev) => ({
          ...prev,
          [feature.id]: err.message,
        }));
      } finally {
        setLoading((prev) => ({
          ...prev,
          [feature.id]: false,
        }));
      }
    },
    [closeModal]
  );

  const handleResponse = (featureId, result) => {
    switch (featureId) {
      case "flashcard-generation":
        setActiveContent({
          type: "flashcards",
          data: result.flashcards,
          title: "Generated Flashcards",
        });
        break;

      case "study-guide":
        setActiveContent({
          type: "study-guide",
          data: result.study_guide,
          title: "Study Guide",
        });
        break;

      case "quiz-generation":
        setActiveContent({
          type: "quiz",
          data: result.questions,
          title: "Practice Quiz",
        });
        break;

      case "chat-tutor":
        setActiveContent({
          type: "chat",
          data: result,
          title: "AI Tutor Response",
        });
        break;

      default:
        break;
    }
  };

  const handleTextChange = useCallback((e) => {
    console.log("Text changed:", e.target.value);
  }, []);

  const handleFileChange = useCallback((e) => {
    console.log("File selected:", e.target.files[0]);
  }, []);

  const renderModalContent = useCallback(
    (featureId) => {
      const feature = features.find((f) => f.id === featureId);
      if (!feature) return null;

      return (
        <div className={styles.modalForm}>
          <ContentInput
            feature={feature}
            onTextChange={(text) => {
              // Store text in state if needed
            }}
            onFileChange={async (data) => {
              if (data.file) {
                await handleFeatureAction(feature, { file: data.file });
              }
            }}
            onSubmit={async (data) => {
              await handleFeatureAction(feature, data);
            }}
          />

          {error[featureId] && (
            <div className={styles.errorMessage}>{error[featureId]}</div>
          )}

          {loading[featureId] && (
            <div className={styles.loadingIndicator}>
              <div className={styles.spinner} />
              <span>Processing your content...</span>
            </div>
          )}
        </div>
      );
    },
    [handleFeatureAction, error, loading]
  );

  const sortedFeatures = useMemo(() => {
    return [...features].sort((a, b) => {
      if (a.isPremium === b.isPremium) return 0;
      return a.isPremium ? 1 : -1;
    });
  }, []);

  // Enhanced feature card component
  const FeatureCard = ({ feature }) => (
    <div
      className={`${styles.featureCard} ${
        feature.isPremium ? styles.premiumFeature : ""
      }`}
      onClick={() => handleFeatureClick(feature)}
      role="button"
      tabIndex={0}
    >
      {feature.isPremium && (
        <div className={styles.premiumBadge}>
          <FiLock /> Premium
        </div>
      )}
      <div className={styles.featureIcon}>{feature.icon}</div>
      <div className={styles.featureContent}>
        <h3 className={styles.featureTitle}>{feature.title}</h3>
        <p className={styles.featureDescription}>{feature.description}</p>
      </div>
      <div className={styles.featureMetrics}>
        {Object.entries(feature.metrics).map(([key, value]) => (
          <div key={key} className={styles.metricItem}>
            <span className={styles.metricValue}>{value}</span>
            <span className={styles.metricLabel}>{key}</span>
          </div>
        ))}
      </div>
      {loading[feature.id] && (
        <div className={styles.featureOverlay}>
          <div className={styles.spinner} />
        </div>
      )}
      {error[feature.id] && (
        <div className={styles.errorMessage}>{error[feature.id]}</div>
      )}
    </div>
  );

  // Add these new components for displaying content
  const FlashcardView = ({ data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFlipped, setIsFlipped] = useState(false);

    return (
      <div className={styles.flashcardContainer}>
        <div
          className={`${styles.flashcard} ${isFlipped ? styles.flipped : ""}`}
          onClick={() => setIsFlipped(!isFlipped)}
        >
          <div className={styles.flashcardFront}>
            <p>{data[currentIndex].front}</p>
          </div>
          <div className={styles.flashcardBack}>
            <p>{data[currentIndex].back}</p>
          </div>
        </div>
        <div className={styles.flashcardControls}>
          <button
            onClick={() => setCurrentIndex((prev) => Math.max(0, prev - 1))}
            disabled={currentIndex === 0}
          >
            Previous
          </button>
          <span>
            {currentIndex + 1} / {data.length}
          </span>
          <button
            onClick={() =>
              setCurrentIndex((prev) => Math.min(data.length - 1, prev + 1))
            }
            disabled={currentIndex === data.length - 1}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const QuizView = ({ data }) => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState({});
    const [showExplanation, setShowExplanation] = useState({});
    const [isSubmitted, setIsSubmitted] = useState({}); // Track submission per question

    const handleAnswer = (questionIndex, key) => {
      if (!isSubmitted[questionIndex]) {
        setAnswers((prev) => ({ ...prev, [questionIndex]: key }));
      }
    };

    const handleSubmit = (questionIndex) => {
      setIsSubmitted((prev) => ({ ...prev, [questionIndex]: true }));
      setShowExplanation((prev) => ({ ...prev, [questionIndex]: true }));
    };

    return (
      <div className={styles.quizContainer}>
        <div className={styles.quizHeader}>
          <div className={styles.questionProgress}>
            {data.map((_, index) => (
              <div
                key={index}
                className={`${styles.progressDot} ${
                  index === currentQuestion ? styles.current : ""
                } ${answers[index] ? styles.answered : ""}`}
                onClick={() => setCurrentQuestion(index)}
              />
            ))}
          </div>
          <span className={styles.questionNumber}>
            Question {currentQuestion + 1} of {data.length}
          </span>
        </div>

        <div className={styles.questionWrapper}>
          <div className={styles.questionCard}>
            <h3>{data[currentQuestion].question}</h3>

            <div className={styles.options}>
              {Object.entries(data[currentQuestion].options).map(
                ([key, value]) => (
                  <button
                    key={key}
                    className={`${styles.option} ${
                      answers[currentQuestion] === key ? styles.selected : ""
                    } ${
                      isSubmitted[currentQuestion]
                        ? key === data[currentQuestion].correct
                          ? styles.correct
                          : answers[currentQuestion] === key
                          ? styles.incorrect
                          : ""
                        : ""
                    }`}
                    onClick={() => handleAnswer(currentQuestion, key)}
                    disabled={isSubmitted[currentQuestion]}
                  >
                    <span className={styles.optionKey}>{key}</span>
                    <span className={styles.optionValue}>{value}</span>
                  </button>
                )
              )}
            </div>

            {showExplanation[currentQuestion] && (
              <div className={styles.explanation}>
                <h4>Explanation</h4>
                <p>{data[currentQuestion].explanation}</p>
              </div>
            )}
          </div>
        </div>

        <div className={styles.quizFooter}>
          <button
            onClick={() => setCurrentQuestion((prev) => prev - 1)}
            disabled={currentQuestion === 0}
            className={styles.navigationButton}
          >
            Previous
          </button>

          {!isSubmitted[currentQuestion] ? (
            <button
              onClick={() => handleSubmit(currentQuestion)}
              disabled={!answers[currentQuestion]}
              className={styles.submitButton}
            >
              Submit Answer
            </button>
          ) : (
            <button
              onClick={() => setCurrentQuestion((prev) => prev + 1)}
              disabled={currentQuestion === data.length - 1}
              className={styles.navigationButton}
            >
              Next Question
            </button>
          )}
        </div>
      </div>
    );
  };

  const StudyGuideView = ({ data }) => {
    const [shownSolutions, setShownSolutions] = useState({});

    const toggleSolution = (index) => {
      setShownSolutions((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    };

    return (
      <div className={styles.studyGuide}>
        <div className={styles.studyGuideContent}>
          <div className={styles.studyGuideSection}>
            <h3>Key Concepts</h3>
            <ul>
              {data.key_concepts.map((concept, index) => (
                <li key={index}>{concept}</li>
              ))}
            </ul>
          </div>

          <div className={styles.studyGuideSection}>
            <h3>Summary</h3>
            <p>{data.summary}</p>
          </div>

          <div className={styles.studyGuideSection}>
            <h3>Practice Problems</h3>
            {data.practice_problems.map((problem, index) => (
              <div key={index} className={styles.practiceProblem}>
                <p className={styles.problemQuestion}>{problem.question}</p>
                <div className={styles.problemSolution}>
                  <button
                    className={styles.solutionToggle}
                    onClick={() => toggleSolution(index)}
                  >
                    {shownSolutions[index] ? "Hide Solution" : "Show Solution"}
                  </button>
                  {shownSolutions[index] && (
                    <div className={styles.solutionContent}>
                      {problem.solution}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const ChatResponseView = ({ data }) => {
    return (
      <div className={styles.chatResponse}>
        <div className={styles.messageThread}>
          {data.messages.map((message, index) => (
            <div
              key={index}
              className={`${styles.message} ${
                message.type === "user" ? styles.userMessage : styles.aiMessage
              }`}
            >
              <div className={styles.messageContent}>
                <p>{message.content}</p>
                {message.type === "ai" && message.references && (
                  <div className={styles.references}>
                    <h4>References</h4>
                    <ul>
                      {message.references.map((ref, i) => (
                        <li key={i}>{ref}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.chatInput}>
          <textarea
            placeholder="Ask a follow-up question..."
            className={styles.chatTextarea}
          />
          <button className={styles.sendButton}>
            <FiSend />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {/* Navigation */}
      <nav className={styles.navbar}>
        <div className={styles.navContent}>
          <Link to="/" className={styles.logo}>
            <img src={bLogo} alt="Bramlo" className={styles.logoIcon} />
          </Link>
          <div className={styles.navLinks}>
            <Link to="/features" className={styles.navLink}>Features</Link>
            <Link to="/pricing" className={styles.navLink}>Pricing</Link>
            <Link to="/about" className={styles.navLink}>About</Link>
            <Link to="/contact" className={styles.navLink}>Contact</Link>
            <Link to="/login" className={styles.loginButton}>Log in</Link>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <main className={styles.heroSection}>
        <div className={styles.heroContent}>
          <div className={styles.glooPlus}>
            <span className={styles.bramloText}>Bramlo</span>
            <span className={styles.plusSign}>+</span>
          </div>
          <h1 className={styles.heroTitle}>
            Grow more with Bramlo+
          </h1>
          <p className={styles.heroDescription}>
            More features. Unlimited resources. Powerful learning tools. Time-saving solutions. And more.
          </p>
          <div className={styles.pricingInfo}>
            Only $49/month. Cancel anytime.
          </div>
          <div className={styles.ctaButtons}>
            <button className={styles.primaryButton}>
              Get Bramlo+ <FiArrowRight />
            </button>
            <button className={styles.secondaryButton}>
              Talk with our team <FiArrowRight />
            </button>
          </div>
        </div>
      </main>

      {/* Features Grid */}
      <section className={styles.featuresSection}>
        <div className={styles.featuresGrid}>
          {sortedFeatures.map((feature) => (
            <FeatureCard key={feature.id} feature={feature} />
          ))}
        </div>
      </section>

      {features.map((feature) => (
        <Modal
          key={feature.id}
          isOpen={activeModal === feature.id}
          onClose={closeModal}
          title={feature.title}
          isPremiumFeature={feature.isPremium}
          isUserPremium={isUserPremium}
        >
          {renderModalContent(feature.id)}
        </Modal>
      ))}

      {activeContent && (
        <div
          className={`${styles.contentViewer} ${
            isFullscreen ? styles.fullscreen : ""
          }`}
        >
          <div className={styles.contentHeader}>
            <h3>{activeContent.title}</h3>
            <div className={styles.contentControls}>
              <button onClick={() => setIsFullscreen(!isFullscreen)}>
                {isFullscreen ? <FiMinimize2 /> : <FiMaximize2 />}
              </button>
              <button onClick={() => setActiveContent(null)}>
                <FiX />
              </button>
            </div>
          </div>
          <div className={styles.contentBody} ref={contentRef}>
            {activeContent.type === "flashcards" && (
              <FlashcardView data={activeContent.data} />
            )}
            {activeContent.type === "quiz" && (
              <QuizView data={activeContent.data} />
            )}
            {activeContent.type === "study-guide" && (
              <StudyGuideView data={activeContent.data} />
            )}
            {activeContent.type === "chat" && (
              <ChatResponseView data={activeContent.data} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(HomePage);
