import React, { useState, useEffect, useRef } from "react";
import styles from "../styles/moderndemo.module.css";
import { motion, AnimatePresence } from "framer-motion";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import aiStar from "../assets/img/ai-star.png";

const Message = ({ message }) => {
  const variants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <motion.div
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3 }}
      style={{
        marginBottom: "1rem",
        display: "flex",
        justifyContent: message.type === "user" ? "flex-end" : "flex-start",
        width: "100%",
      }}
    >
      <div
        style={{
          maxWidth: "80%",
          padding: "1rem",
          backgroundColor:
            message.type === "user" ? "rgba(96, 165, 250, 0.1)" : "transparent",
          borderRadius: message.type === "user" ? "12px" : "0",
          display: "flex",
          alignItems: "flex-start",
          gap: "1rem",
        }}
      >
        <motion.div
          initial={{ clipPath: "inset(0 100% 0 0)" }}
          animate={{ clipPath: "inset(0 0% 0 0)" }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          style={{
            flex: 1,
            overflow: "hidden",
          }}
        >
          {message.content}
        </motion.div>
      </div>
    </motion.div>
  );
};

const ModernDemo = () => {
  const [messages, setMessages] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const chatAreaRef = useRef(null);
  const demoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Start demo when more than 50% of the component is visible
        if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the component is visible
        rootMargin: "0px",
      }
    );

    if (demoRef.current) {
      observer.observe(demoRef.current);
    }

    return () => {
      if (demoRef.current) {
        observer.unobserve(demoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (chatAreaRef.current) {
      if (messages.length === 1) {
        // For the first message, start at the top
        chatAreaRef.current.scrollTop = 0;
      } else if (messages.length > 1) {
        // For subsequent messages, scroll to show the new message
        chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
      }
    }
  }, [messages]);

  useEffect(() => {
    let timer;
    if (isVisible && messages.length < conversation.length) {
      timer = setTimeout(
        () => {
          setMessages((prev) => [...prev, conversation[messages.length]]);
        },
        messages.length === 0 ? 300 : 1600
      );
      return () => clearTimeout(timer);
    }
  }, [messages, isVisible]);

  // Pre-scripted conversation showcasing different subjects
  const conversation = [
    {
      type: "assistant",
      content: (
        <div
          style={{
            marginBottom: "2.5rem",
            paddingBottom: "1.5rem",
            borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
            position: "relative",
          }}
        >
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "600",
              color: "#60a5fa",
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                background:
                  "linear-gradient(135deg, #f0f9ff 0%, #60a5fa 50%, #3b82f6 100%)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Hi, I'm Lumi!
            </span>
          </h2>
          <p
            style={{
              marginTop: "1rem",
              color: "rgba(255, 255, 255, 0.9)",
              fontSize: "1rem",
              lineHeight: "1.5",
              maxWidth: "90%",
            }}
          >
            I'm your AI learning assistant, ready to help you master any subject
            through interactive explanations and practice.
          </p>
        </div>
      ),
    },
    {
      type: "user",
      content: "Can you help me understand Shakespeare's Hamlet?",
    },
    {
      type: "assistant",
      content: (
        <div
          style={{
            marginBottom: "2.5rem",
            paddingBottom: "1.5rem",
            borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
            position: "relative",
          }}
        >
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "600",
              color: "#60a5fa",
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                background: "linear-gradient(90deg, #60a5fa 0%, #34d399 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                paddingBottom: "2px",
                borderBottom: "2px solid rgba(96, 165, 250, 0.2)",
              }}
            >
              Hamlet's Famous Soliloquy Analysis
            </span>
          </h2>
          <p
            style={{
              marginTop: "1rem",
              color: "rgba(255, 255, 255, 0.9)",
              fontSize: "1rem",
              lineHeight: "1.5",
              maxWidth: "90%",
            }}
          >
            Let's break down the "To be, or not to be" soliloquy:
          </p>

          <div className={styles.codeBlock}>
            <code>
              "To be, or not to be, that is the question: Whether 'tis nobler in
              the mind to suffer The slings and arrows of outrageous fortune..."
            </code>
          </div>

          <div
            style={{
              background: "rgba(96, 165, 250, 0.1)",
              padding: "24px",
              borderRadius: "8px",
              marginBottom: "24px",
              border: "1px solid rgba(96, 165, 250, 0.2)",
            }}
          >
            <h3
              style={{
                marginBottom: "20px",
                color: "#60a5fa",
                fontSize: "1.5em",
                fontWeight: "bold",
              }}
            >
              Key Themes in Hamlet's Soliloquy
            </h3>

            <div
              style={{
                border: "1px solid rgba(96, 165, 250, 0.2)",
                borderRadius: "6px",
                overflow: "hidden",
              }}
            >
              {/* Table Header */}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1.2fr 2fr 1fr",
                  gap: "24px",
                  background: "rgba(96, 165, 250, 0.2)",
                  padding: "16px",
                  borderBottom: "1px solid rgba(96, 165, 250, 0.2)",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1em",
                    color: "#60a5fa",
                    textAlign: "left",
                  }}
                >
                  Theme
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1em",
                    color: "#60a5fa",
                    textAlign: "left",
                  }}
                >
                  Analysis
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1em",
                    color: "#60a5fa",
                    textAlign: "center",
                  }}
                >
                  Modern Parallel
                </div>
              </div>

              {/* Table Rows */}
              {[
                {
                  theme: "Existence vs. Death",
                  analysis: "Opening lines explore life's question",
                  parallel: "Making life-changing decisions",
                },
                {
                  theme: "Death",
                  analysis: "Comparing death to sleep",
                  parallel: "Uncertainty",
                },
                {
                  theme: "Life's Struggles",
                  analysis:
                    "Weighing mortal suffering against death's uncertainty",
                  parallel: "Overcoming challenges",
                },
              ].map((row, index) => (
                <div
                  key={index}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1.2fr 2fr 1fr",
                    gap: "24px",
                    padding: "16px",
                    background:
                      index % 2 === 0
                        ? "rgba(96, 165, 250, 0.05)"
                        : "transparent",
                    borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      color: "#60a5fa",
                      fontWeight: "500",
                    }}
                  >
                    {row.theme}
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    {row.analysis}
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      color: "rgba(255, 255, 255, 0.7)",
                      fontStyle: "italic",
                    }}
                  >
                    {row.parallel}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Hamlet's Dilemma Diagram */}
          <div
            style={{
              background: "rgba(96, 165, 250, 0.05)",
              padding: "32px",
              borderRadius: "8px",
              marginBottom: "24px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
              position: "relative",
            }}
          >
            <h3
              style={{
                marginBottom: "24px",
                color: "#60a5fa",
                fontSize: "1.2em",
                textAlign: "center",
              }}
            >
              Hamlet's Dilemma
            </h3>

            <div
              style={{
                width: "100%",
                height: "200px",
                position: "relative",
              }}
            >
              <svg width="100%" height="100%" viewBox="0 0 400 200">
                {/* Gradient Definition */}
                <defs>
                  <linearGradient
                    id="curveGradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: "#60a5fa", stopOpacity: 0.8 }}
                    />
                    <stop
                      offset="50%"
                      style={{ stopColor: "#ffffff", stopOpacity: 0.9 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#60a5fa", stopOpacity: 0.8 }}
                    />
                  </linearGradient>
                  {/* Glow Effect */}
                  <filter id="glow">
                    <feGaussianBlur stdDeviation="2" result="coloredBlur" />
                    <feMerge>
                      <feMergeNode in="coloredBlur" />
                      <feMergeNode in="SourceGraphic" />
                    </feMerge>
                  </filter>
                </defs>

                {/* Main Curve */}
                <path
                  d="M 50 150 Q 200 50 350 150"
                  fill="none"
                  stroke="url(#curveGradient)"
                  strokeWidth="3"
                  strokeLinecap="round"
                />

                {/* Midpoint Highlight */}
                <circle
                  cx="200"
                  cy="100"
                  r="6"
                  fill="#ffffff"
                  filter="url(#glow)"
                />

                {/* Labels */}
                <text
                  x="40"
                  y="180"
                  fill="#60a5fa"
                  fontSize="16"
                  fontWeight="bold"
                  textAnchor="middle"
                >
                  Life
                </text>
                <text
                  x="360"
                  y="180"
                  fill="#60a5fa"
                  fontSize="16"
                  fontWeight="bold"
                  textAnchor="middle"
                >
                  Death
                </text>
              </svg>
            </div>

            {/* Caption */}
            <p
              style={{
                textAlign: "center",
                color: "rgba(255, 255, 255, 0.7)",
                fontSize: "0.9em",
                marginTop: "24px",
                fontStyle: "italic",
              }}
            >
              Hamlet's conflict lies at the intersection of these two extremes
            </p>
          </div>

          <div className={styles.blockquote}>
            <p>
              Modern Interpretation: This passage resonates with contemporary
              themes of decision-making and facing life's challenges.
            </p>
          </div>
        </div>
      ),
    },
    {
      type: "user",
      content:
        "How about something in Chemistry? I'm struggling with chemical reactions.",
    },
    {
      type: "assistant",
      content: (
        <div
          style={{
            marginBottom: "2.5rem",
            paddingBottom: "1.5rem",
            borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
            position: "relative",
          }}
        >
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "600",
              color: "#60a5fa",
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                background: "linear-gradient(90deg, #60a5fa 0%, #34d399 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                paddingBottom: "2px",
                borderBottom: "2px solid rgba(96, 165, 250, 0.2)",
              }}
            >
              Chemical Reactions in Everyday Life
            </span>
          </h2>
          <p>
            Let's understand chemical reactions through a fun example - baking a
            cake!
          </p>

          <div className={styles.chemicalReaction}>
            <span className={styles.molecule}>NaHCO₃</span>
            <span>+</span>
            <span className={styles.molecule}>CH₃COOH</span>
            <span className={styles.arrow}>→</span>
            <span className={styles.molecule}>CH₃COONa</span>
            <span>+</span>
            <span className={styles.molecule}>H₂O</span>
            <span>+</span>
            <span className={styles.molecule}>CO₂</span>
          </div>

          <h4>What's happening?</h4>
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              margin: "1rem 0",
              display: "flex",
              flexDirection: "column",
              gap: "0.75rem",
            }}
          >
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                color: "rgba(255, 255, 255, 0.9)",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "6px",
                  height: "6px",
                  backgroundColor: "#60a5fa",
                  borderRadius: "50%",
                }}
              ></span>
              Baking soda (base) meets vinegar (acid)
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                color: "rgba(255, 255, 255, 0.9)",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "6px",
                  height: "6px",
                  backgroundColor: "#60a5fa",
                  borderRadius: "50%",
                }}
              ></span>
              They react to form sodium acetate and water
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                color: "rgba(255, 255, 255, 0.9)",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "6px",
                  height: "6px",
                  backgroundColor: "#60a5fa",
                  borderRadius: "50%",
                }}
              ></span>
              CO₂ bubbles make the cake rise!
            </li>
          </ul>

          <h3>Reaction Breakdown</h3>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Reactants</th>
                <th>Products</th>
                <th>Observation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>NaHCO₃</td>
                <td>CH₃COONa</td>
                <td>Salt forms</td>
              </tr>
              <tr>
                <td>CH₃COOH</td>
                <td>H₂O</td>
                <td>Liquid appears</td>
              </tr>
              <tr>
                <td>-</td>
                <td>CO₂</td>
                <td>Bubbles form</td>
              </tr>
            </tbody>
          </table>

          <div
            style={{
              marginTop: "32px",
              background: "rgba(96, 165, 250, 0.05)",
              padding: "24px",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "300px",
                position: "relative",
                padding: "20px",
              }}
            >
              <svg width="100%" height="100%" viewBox="0 0 400 300">
                {/* Gradient Definition */}
                <defs>
                  <linearGradient
                    id="curveGradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: "#60a5fa", stopOpacity: 0.8 }}
                    />
                    <stop
                      offset="50%"
                      style={{ stopColor: "#34d399", stopOpacity: 0.9 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#60a5fa", stopOpacity: 0.8 }}
                    />
                  </linearGradient>
                  {/* Glow Effect */}
                  <filter id="glow">
                    <feGaussianBlur stdDeviation="2" result="coloredBlur" />
                    <feMerge>
                      <feMergeNode in="coloredBlur" />
                      <feMergeNode in="SourceGraphic" />
                    </feMerge>
                  </filter>
                  {/* Arrow Marker */}
                  <marker
                    id="arrowhead"
                    markerWidth="10"
                    markerHeight="7"
                    refX="9"
                    refY="3.5"
                    orient="auto"
                  >
                    <polygon
                      points="0 0, 10 3.5, 0 7"
                      fill="rgba(255,255,255,0.7)"
                    />
                  </marker>
                </defs>

                {/* Background Grid */}
                <g>
                  {[...Array(10)].map((_, i) => (
                    <line
                      key={`vgrid-${i}`}
                      x1={50 + i * 30}
                      y1="50"
                      x2={50 + i * 30}
                      y2="250"
                      stroke="rgba(255,255,255,0.05)"
                      strokeWidth="1"
                    />
                  ))}
                  {[...Array(8)].map((_, i) => (
                    <line
                      key={`hgrid-${i}`}
                      x1="50"
                      y1={50 + i * 25}
                      x2="350"
                      y2={50 + i * 25}
                      stroke="rgba(255,255,255,0.05)"
                      strokeWidth="1"
                    />
                  ))}
                </g>

                {/* Title */}
                <text
                  x="200"
                  y="30"
                  fill="#60a5fa"
                  fontSize="18"
                  fontWeight="bold"
                  textAnchor="middle"
                >
                  Reaction Progress
                </text>

                {/* Axes with Tick Marks */}
                <line
                  x1="50"
                  y1="250"
                  x2="350"
                  y2="250"
                  stroke="rgba(255,255,255,0.3)"
                  strokeWidth="1.5"
                />
                <line
                  x1="50"
                  y1="250"
                  x2="50"
                  y2="50"
                  stroke="rgba(255,255,255,0.3)"
                  strokeWidth="1.5"
                />

                {/* X-Axis Ticks */}
                {[0, 1, 2, 3, 4].map((tick) => (
                  <g key={`xtick-${tick}`}>
                    <line
                      x1={50 + tick * 75}
                      y1="250"
                      x2={50 + tick * 75}
                      y2="255"
                      stroke="rgba(255,255,255,0.3)"
                      strokeWidth="1.5"
                    />
                    <text
                      x={50 + tick * 75}
                      y="270"
                      fill="rgba(255,255,255,0.5)"
                      fontSize="10"
                      textAnchor="middle"
                    >
                      {tick * 25}s
                    </text>
                  </g>
                ))}

                {/* Y-Axis Ticks */}
                {[0, 1, 2, 3, 4].map((tick) => (
                  <g key={`ytick-${tick}`}>
                    <line
                      x1="45"
                      y1={250 - tick * 50}
                      x2="50"
                      y2={250 - tick * 50}
                      stroke="rgba(255,255,255,0.3)"
                      strokeWidth="1.5"
                    />
                    <text
                      x="40"
                      y={250 - tick * 50}
                      fill="rgba(255,255,255,0.5)"
                      fontSize="10"
                      textAnchor="end"
                      dominantBaseline="middle"
                    >
                      {tick * 25}
                    </text>
                  </g>
                ))}

                {/* Axis Labels */}
                <text
                  x="200"
                  y="290"
                  fill="rgba(255,255,255,0.8)"
                  fontSize="14"
                  textAnchor="middle"
                >
                  Time (seconds)
                </text>
                <text
                  x="25"
                  y="150"
                  fill="rgba(255,255,255,0.8)"
                  fontSize="14"
                  textAnchor="middle"
                  transform="rotate(-90, 25, 150)"
                >
                  Energy Level (kJ)
                </text>

                {/* Energy Direction Arrows */}
                <path
                  d="M 80 180 L 80 120"
                  stroke="rgba(255,255,255,0.4)"
                  strokeWidth="1.5"
                  markerEnd="url(#arrowhead)"
                />
                <path
                  d="M 280 180 L 280 220"
                  stroke="rgba(255,255,255,0.4)"
                  strokeWidth="1.5"
                  markerEnd="url(#arrowhead)"
                />

                {/* Reaction Curve */}
                <path
                  d="M 50 200 Q 125 100 200 220 T 350 240"
                  fill="none"
                  stroke="url(#curveGradient)"
                  strokeWidth="3"
                  filter="url(#glow)"
                />

                {/* Stage Markers with Different Shapes */}
                <circle
                  cx="50"
                  cy="200"
                  r="6"
                  fill="#60a5fa"
                  filter="url(#glow)"
                />
                <polygon
                  points="200,213 207,227 193,227"
                  fill="#34d399"
                  filter="url(#glow)"
                />
                <rect
                  x="346"
                  y="236"
                  width="8"
                  height="8"
                  fill="#60a5fa"
                  transform="rotate(45, 350, 240)"
                  filter="url(#glow)"
                />

                {/* Stage Labels with Increased Size */}
                <text
                  x="50"
                  y="175"
                  fill="rgba(255,255,255,0.8)"
                  fontSize="12"
                  textAnchor="middle"
                  fontWeight="bold"
                >
                  Start
                </text>
                <text
                  x="200"
                  y="195"
                  fill="rgba(255,255,255,0.8)"
                  fontSize="12"
                  textAnchor="middle"
                  fontWeight="bold"
                >
                  CO₂ Release
                </text>
                <text
                  x="350"
                  y="215"
                  fill="rgba(255,255,255,0.8)"
                  fontSize="12"
                  textAnchor="middle"
                  fontWeight="bold"
                >
                  Products Form
                </text>

                {/* Annotations with Better Positioning */}
                <text
                  x="125"
                  y="90"
                  fill="rgba(255,255,255,0.7)"
                  fontSize="12"
                  textAnchor="middle"
                >
                  Energy rises as
                </text>
                <text
                  x="125"
                  y="105"
                  fill="rgba(255,255,255,0.7)"
                  fontSize="12"
                  textAnchor="middle"
                >
                  reactants mix
                </text>

                <text
                  x="275"
                  y="230"
                  fill="rgba(255,255,255,0.7)"
                  fontSize="12"
                  textAnchor="middle"
                >
                  Energy stabilizes as
                </text>
                <text
                  x="275"
                  y="245"
                  fill="rgba(255,255,255,0.7)"
                  fontSize="12"
                  textAnchor="middle"
                >
                  products form
                </text>
              </svg>
            </div>
          </div>
        </div>
      ),
    },
    {
      type: "user",
      content: "Could you help me with calculus derivatives?",
    },
    {
      type: "assistant",
      content: (
        <div style={{ padding: "32px 0" }}>
          {/* Title Section with Gradient Background */}
          <div
            style={{
              marginBottom: "2.5rem",
              paddingBottom: "1.5rem",
              borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
              position: "relative",
            }}
          >
            <h2
              style={{
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#60a5fa",
                marginBottom: "1rem",
                marginTop: 0,
                display: "flex",
                alignItems: "center",
                lineHeight: 1,
              }}
            >
              <span
                style={{
                  background:
                    "linear-gradient(90deg, #60a5fa 0%, #34d399 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  paddingBottom: "2px",
                  borderBottom: "2px solid rgba(96, 165, 250, 0.2)",
                  lineHeight: 1,
                }}
              >
                Understanding Derivatives through Motion
              </span>
            </h2>
            <p
              style={{
                marginTop: "1rem",
                color: "rgba(255, 255, 255, 0.9)",
                fontSize: "1rem",
                lineHeight: "1.5",
                maxWidth: "90%",
              }}
            >
              Let's explore how derivatives help us understand a car's motion!
            </p>
          </div>
          {/* Derivative Explanation Box */}
          <div
            style={{
              background: "rgba(96, 165, 250, 0.1)",
              padding: "24px",
              borderRadius: "8px",
              marginBottom: "24px",
              border: "1px solid rgba(96, 165, 250, 0.2)",
            }}
          >
            <p
              style={{
                margin: 0,
                color: "rgba(255, 255, 255, 0.9)",
                fontSize: "14px",
              }}
            >
              The velocity is the derivative of position:
              <span style={{ color: "#60a5fa", marginLeft: "8px" }}>
                x'(t) = dx/dt
              </span>
            </p>
          </div>

          {/* Position and Velocity Equations */}
          <div
            style={{
              marginBottom: "24px",
              padding: "16px",
              background: "rgba(96, 165, 250, 0.05)",
              borderRadius: "8px",
            }}
          >
            <div style={{ color: "#60a5fa", marginBottom: "8px" }}>
              Position: x(t) = t² + 2t
            </div>
            <div style={{ color: "#34d399" }}>Velocity: x'(t) = 2t + 2</div>
          </div>

          {/* Data Table */}
          <div
            style={{
              marginBottom: "2.5rem",
              borderRadius: "8px",
              overflow: "hidden",
              border: "1px solid rgba(96, 165, 250, 0.2)",
              background: "rgba(96, 165, 250, 0.05)",
            }}
          >
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                tableLayout: "fixed",
              }}
            >
              <thead>
                <tr style={{ background: "rgba(96, 165, 250, 0.2)" }}>
                  <th
                    style={{
                      padding: "1rem",
                      textAlign: "center",
                      color: "#60a5fa",
                      fontWeight: "bold",
                      borderBottom: "2px solid rgba(96, 165, 250, 0.3)",
                      width: "30%",
                    }}
                  >
                    Time (s)
                  </th>
                  <th
                    style={{
                      padding: "1rem",
                      textAlign: "center",
                      color: "#60a5fa",
                      fontWeight: "bold",
                      borderBottom: "2px solid rgba(96, 165, 250, 0.3)",
                      width: "35%",
                    }}
                  >
                    Position (m)
                  </th>
                  <th
                    style={{
                      padding: "1rem",
                      textAlign: "center",
                      color: "#60a5fa",
                      fontWeight: "bold",
                      borderBottom: "2px solid rgba(96, 165, 250, 0.3)",
                      width: "35%",
                    }}
                  >
                    Velocity (m/s)
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  { time: 0, position: 0, velocity: 2 },
                  { time: 1, position: 3, velocity: 4 },
                  { time: 2, position: 8, velocity: 6 },
                  { time: 3, position: 15, velocity: 8 },
                ].map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      background:
                        index % 2 === 0
                          ? "transparent"
                          : "rgba(96, 165, 250, 0.05)",
                      borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
                    }}
                  >
                    <td
                      style={{
                        padding: "1rem",
                        textAlign: "center",
                        color: "rgba(255, 255, 255, 0.9)",
                      }}
                    >
                      {row.time}
                    </td>
                    <td
                      style={{
                        padding: "1rem",
                        textAlign: "center",
                        color: "rgba(255, 255, 255, 0.9)",
                      }}
                    >
                      {row.position}
                    </td>
                    <td
                      style={{
                        padding: "1rem",
                        textAlign: "center",
                        color: "rgba(255, 255, 255, 0.9)",
                      }}
                    >
                      {row.velocity}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Velocity Graph */}
          <div
            style={{
              marginTop: "2.5rem",
              marginBottom: "2.5rem",
              background: "rgba(96, 165, 250, 0.05)",
              padding: "1.5rem",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "300px",
                position: "relative",
                padding: "20px",
              }}
            >
              <svg width="100%" height="100%" viewBox="0 0 400 300">
                {/* Gradient Definition */}
                <defs>
                  <linearGradient
                    id="velocityGradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: "#60a5fa", stopOpacity: 0.8 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#34d399", stopOpacity: 0.8 }}
                    />
                  </linearGradient>
                  {/* Glow Effect */}
                  <filter id="velocityGlow">
                    <feGaussianBlur stdDeviation="2" result="coloredBlur" />
                    <feMerge>
                      <feMergeNode in="coloredBlur" />
                      <feMergeNode in="SourceGraphic" />
                    </feMerge>
                  </filter>
                </defs>

                {/* Background Grid */}
                <g>
                  {[...Array(8)].map((_, i) => (
                    <line
                      key={`vgrid-${i}`}
                      x1={50 + i * 43}
                      y1="50"
                      x2={50 + i * 43}
                      y2="250"
                      stroke="rgba(255,255,255,0.03)"
                      strokeWidth="1"
                    />
                  ))}
                  {[...Array(6)].map((_, i) => (
                    <line
                      key={`hgrid-${i}`}
                      x1="50"
                      y1={50 + i * 40}
                      x2="350"
                      y2={50 + i * 40}
                      stroke="rgba(255,255,255,0.03)"
                      strokeWidth="1"
                    />
                  ))}
                </g>

                {/* Title */}
                <text
                  x="200"
                  y="30"
                  fill="#60a5fa"
                  fontSize="16"
                  fontWeight="bold"
                  textAnchor="middle"
                >
                  Velocity vs Time
                </text>

                {/* Axes */}
                <line
                  x1="50"
                  y1="250"
                  x2="350"
                  y2="250"
                  stroke="rgba(255,255,255,0.3)"
                  strokeWidth="1.5"
                />
                <line
                  x1="50"
                  y1="250"
                  x2="50"
                  y2="50"
                  stroke="rgba(255,255,255,0.3)"
                  strokeWidth="1.5"
                />

                {/* Velocity Line */}
                <path
                  d="M 50 200 L 350 50"
                  stroke="url(#velocityGradient)"
                  strokeWidth="3"
                  fill="none"
                  filter="url(#velocityGlow)"
                />

                {/* Data Points */}
                {[
                  { time: 0, velocity: 2 },
                  { time: 1, velocity: 4 },
                  { time: 2, velocity: 6 },
                  { time: 3, velocity: 8 },
                ].map((point, index) => (
                  <g key={`point-${index}`}>
                    <circle
                      cx={50 + point.time * 100}
                      cy={250 - point.velocity * 25}
                      r="8"
                      fill="#60a5fa"
                      stroke="white"
                      strokeWidth="2"
                      filter="url(#velocityGlow)"
                    />
                    <text
                      x={50 + point.time * 100}
                      y={235 - point.velocity * 25}
                      fill="rgba(255,255,255,0.9)"
                      fontSize="14"
                      fontWeight="bold"
                      textAnchor="middle"
                    >
                      {point.velocity} m/s
                    </text>
                  </g>
                ))}

                {/* X-Axis Ticks and Labels */}
                {[0, 1, 2, 3].map((tick) => (
                  <g key={`xtick-${tick}`}>
                    <line
                      x1={50 + tick * 100}
                      y1="250"
                      x2={50 + tick * 100}
                      y2="255"
                      stroke="rgba(255,255,255,0.3)"
                      strokeWidth="1.5"
                    />
                    <text
                      x={50 + tick * 100}
                      y="270"
                      fill="rgba(255,255,255,0.7)"
                      fontSize="12"
                      textAnchor="middle"
                    >
                      {tick}s
                    </text>
                  </g>
                ))}

                {/* Y-Axis Ticks and Labels */}
                {[0, 2, 4, 6, 8].map((tick) => (
                  <g key={`ytick-${tick}`}>
                    <line
                      x1="45"
                      y1={250 - tick * 25}
                      x2="50"
                      y2={250 - tick * 25}
                      stroke="rgba(255,255,255,0.3)"
                      strokeWidth="1.5"
                    />
                    <text
                      x="40"
                      y={250 - tick * 25}
                      fill="rgba(255,255,255,0.7)"
                      fontSize="12"
                      textAnchor="end"
                      dominantBaseline="middle"
                    >
                      {tick}
                    </text>
                  </g>
                ))}

                {/* Axis Labels */}
                <text
                  x="200"
                  y="290"
                  fill="rgba(255,255,255,0.8)"
                  fontSize="14"
                  textAnchor="middle"
                >
                  Time (seconds)
                </text>
                <text
                  x="25"
                  y="150"
                  fill="rgba(255,255,255,0.8)"
                  fontSize="14"
                  textAnchor="middle"
                  transform="rotate(-90, 25, 150)"
                >
                  Velocity (m/s)
                </text>

                {/* Annotation */}
                <text
                  x="200"
                  y="80"
                  fill="rgba(255,255,255,0.8)"
                  fontSize="14"
                  fontWeight="bold"
                  textAnchor="middle"
                >
                  Velocity increases linearly with time
                </text>
              </svg>
            </div>
          </div>

          {/* Key Insight */}
          <div
            style={{
              marginTop: "2rem",
              padding: "1.25rem",
              background: "rgba(96, 165, 250, 0.1)",
              borderRadius: "8px",
              textAlign: "center",
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: "1rem",
                color: "rgba(255, 255, 255, 0.9)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <span style={{ fontSize: "0.875rem" }}>🚗</span>
              At t=3 seconds, the car's speed is{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "#34d399",
                }}
              >
                8 m/s
              </span>
              !
            </p>
          </div>
        </div>
      ),
    },
    {
      type: "user",
      content: "I need help with Spanish conjugation.",
    },
    {
      type: "assistant",
      content: (
        <div
          style={{
            marginBottom: "2.5rem",
            paddingBottom: "1.5rem",
            borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
            position: "relative",
          }}
        >
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "600",
              color: "#60a5fa",
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                background: "linear-gradient(90deg, #60a5fa 0%, #34d399 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                paddingBottom: "2px",
                borderBottom: "2px solid rgba(96, 165, 250, 0.2)",
              }}
            >
              Mastering the Present Tense of 'Ser'
            </span>
          </h2>
          <p
            style={{ marginBottom: "20px", color: "rgba(255, 255, 255, 0.8)" }}
          >
            Let's master one of the most important Spanish verbs: 'ser' (to be).
            This verb is essential for describing permanent characteristics and
            identity.
          </p>

          <div className={styles.codeBlock} style={{ marginBottom: "24px" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ background: "rgba(96, 165, 250, 0.1)" }}>
                  <th
                    style={{ padding: "12px", textAlign: "left", width: "25%" }}
                  >
                    Subject
                  </th>
                  <th
                    style={{ padding: "12px", textAlign: "left", width: "25%" }}
                  >
                    Conjugation
                  </th>
                  <th
                    style={{ padding: "12px", textAlign: "left", width: "25%" }}
                  >
                    Pronunciation
                  </th>
                  <th
                    style={{ padding: "12px", textAlign: "left", width: "25%" }}
                  >
                    English
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  { subject: "yo", verb: "soy", pron: "yoh soy", eng: "I am" },
                  {
                    subject: "tú",
                    verb: "eres",
                    pron: "too eh-rehs",
                    eng: "you are",
                  },
                  {
                    subject: "él/ella",
                    verb: "es",
                    pron: "ehs",
                    eng: "he/she is",
                  },
                  {
                    subject: "nosotros",
                    verb: "somos",
                    pron: "noh-soh-trohs soh-mohs",
                    eng: "we are",
                  },
                  {
                    subject: "vosotros",
                    verb: "sois",
                    pron: "boh-soh-trohs sois",
                    eng: "you all are",
                  },
                  {
                    subject: "ellos/ellas",
                    verb: "son",
                    pron: "eh-yohs sohn",
                    eng: "they are",
                  },
                ].map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      background:
                        index % 2 === 0
                          ? "rgba(96, 165, 250, 0.05)"
                          : "transparent",
                    }}
                  >
                    <td style={{ padding: "12px" }}>
                      <span style={{ fontWeight: "bold" }}>{row.subject}</span>
                    </td>
                    <td style={{ padding: "12px" }}>
                      <span style={{ color: "#60a5fa" }}>{row.verb}</span>
                    </td>
                    <td
                      style={{
                        padding: "12px",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      {row.pron}
                    </td>
                    <td
                      style={{
                        padding: "12px",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      {row.eng}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            style={{
              background: "rgba(96, 165, 250, 0.1)",
              padding: "24px",
              borderRadius: "8px",
              marginBottom: "24px",
            }}
          >
            <h3 style={{ marginBottom: "16px", color: "#60a5fa" }}>
              Fill in the Blanks
            </h3>
            <p
              style={{
                marginBottom: "20px",
                color: "rgba(255, 255, 255, 0.8)",
              }}
            >
              Complete these sentences with the correct form of 'ser':
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {/* Question 1 */}
              <div
                style={{
                  background: "rgba(96, 165, 250, 0.05)",
                  padding: "16px",
                  borderRadius: "6px",
                  position: "relative",
                }}
              >
                <div style={{ marginBottom: "8px" }}>
                  <span
                    style={{
                      color: "#60a5fa",
                      marginRight: "12px",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    1
                  </span>
                  <span>Nosotros </span>
                  <span
                    style={{
                      borderBottom: "2px solid rgba(96, 165, 250, 0.5)",
                      padding: "0 4px",
                      margin: "0 4px",
                      minWidth: "60px",
                      display: "inline-block",
                    }}
                  ></span>
                  <span> de España</span>
                </div>
                <div
                  style={{
                    background: "rgba(0, 0, 0, 0.2)",
                    padding: "12px",
                    borderRadius: "4px",
                    color: "rgba(255, 255, 255, 0.6)",
                    fontSize: "0.9em",
                    marginTop: "8px",
                  }}
                >
                  (We are from Spain)
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: "16px",
                    top: "16px",
                    fontSize: "0.8em",
                    color: "rgba(96, 165, 250, 0.7)",
                  }}
                >
                  Hint: We → nosotros
                </div>
              </div>

              {/* Question 2 */}
              <div
                style={{
                  background: "rgba(96, 165, 250, 0.05)",
                  padding: "16px",
                  borderRadius: "6px",
                  position: "relative",
                }}
              >
                <div style={{ marginBottom: "8px" }}>
                  <span
                    style={{
                      color: "#60a5fa",
                      marginRight: "12px",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    2
                  </span>
                  <span>¿Tú </span>
                  <span
                    style={{
                      borderBottom: "2px solid rgba(96, 165, 250, 0.5)",
                      padding: "0 4px",
                      margin: "0 4px",
                      minWidth: "50px",
                      display: "inline-block",
                    }}
                  ></span>
                  <span> médico?</span>
                </div>
                <div
                  style={{
                    background: "rgba(0, 0, 0, 0.2)",
                    padding: "12px",
                    borderRadius: "4px",
                    color: "rgba(255, 255, 255, 0.6)",
                    fontSize: "0.9em",
                    marginTop: "8px",
                  }}
                >
                  (Are you a doctor?)
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: "16px",
                    top: "16px",
                    fontSize: "0.8em",
                    color: "rgba(96, 165, 250, 0.7)",
                  }}
                >
                  Hint: You → tú
                </div>
              </div>

              {/* Question 3 */}
              <div
                style={{
                  background: "rgba(96, 165, 250, 0.05)",
                  padding: "16px",
                  borderRadius: "6px",
                  position: "relative",
                }}
              >
                <div style={{ marginBottom: "8px" }}>
                  <span
                    style={{
                      color: "#60a5fa",
                      marginRight: "12px",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    3
                  </span>
                  <span>Ellos </span>
                  <span
                    style={{
                      borderBottom: "2px solid rgba(96, 165, 250, 0.5)",
                      padding: "0 4px",
                      margin: "0 4px",
                      minWidth: "40px",
                      display: "inline-block",
                    }}
                  ></span>
                  <span> mis hermanos.</span>
                </div>
                <div
                  style={{
                    background: "rgba(0, 0, 0, 0.2)",
                    padding: "12px",
                    borderRadius: "4px",
                    color: "rgba(255, 255, 255, 0.6)",
                    fontSize: "0.9em",
                    marginTop: "8px",
                  }}
                >
                  (They are my brothers)
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: "16px",
                    top: "16px",
                    fontSize: "0.8em",
                    color: "rgba(96, 165, 250, 0.7)",
                  }}
                >
                  Hint: They → ellos
                </div>
              </div>
            </div>

            {/* Answer Key */}
            <div
              style={{
                borderTop: "1px solid rgba(96, 165, 250, 0.2)",
                paddingTop: "20px",
                marginTop: "20px",
              }}
            >
              <h4
                style={{
                  color: "#60a5fa",
                  marginBottom: "12px",
                  fontSize: "0.9em",
                }}
              >
                Answer Key
              </h4>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "16px",
                  color: "rgba(255, 255, 255, 0.6)",
                  fontSize: "0.9em",
                }}
              >
                <div>1. somos</div>
                <div>2. eres</div>
                <div>3. son</div>
              </div>
            </div>

            <div
              style={{
                marginTop: "20px",
                padding: "12px",
                background: "rgba(96, 165, 250, 0.05)",
                borderRadius: "6px",
                fontSize: "0.9em",
                color: "rgba(255, 255, 255, 0.8)",
              }}
            >
              <span style={{ color: "#60a5fa" }}>💡 Tip:</span> Notice how the
              form of 'ser' changes based on who is performing the action.
            </div>
          </div>
        </div>
      ),
    },
    {
      type: "user",
      content: "Can you explain how vaccines work?",
    },
    {
      type: "assistant",
      content: (
        <div
          style={{
            marginBottom: "2.5rem",
            paddingBottom: "1.5rem",
            borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
            position: "relative",
          }}
        >
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "600",
              color: "#60a5fa",
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                background: "linear-gradient(90deg, #60a5fa 0%, #34d399 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                paddingBottom: "2px",
                borderBottom: "2px solid rgba(96, 165, 250, 0.2)",
              }}
            >
              Understanding Vaccines
            </span>
          </h2>
          <p
            style={{
              marginTop: "1rem",
              color: "rgba(255, 255, 255, 0.9)",
              fontSize: "1rem",
              lineHeight: "1.5",
              maxWidth: "90%",
            }}
          >
            Let's break down vaccine biology with a simple analogy!
          </p>

          <div
            style={{
              marginTop: "2rem",
              marginBottom: "2.5rem",
              background: "rgba(96, 165, 250, 0.05)",
              borderRadius: "8px",
              overflow: "hidden",
              border: "1px solid rgba(96, 165, 250, 0.2)",
            }}
          >
            <h3
              style={{
                padding: "1rem",
                margin: 0,
                background: "rgba(96, 165, 250, 0.1)",
                borderBottom: "1px solid rgba(96, 165, 250, 0.2)",
                color: "#60a5fa",
                fontSize: "1.25rem",
                fontWeight: "600",
              }}
            >
              The Immune System Security Team
            </h3>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                tableLayout: "fixed",
              }}
            >
              <thead>
                <tr
                  style={{
                    background: "rgba(96, 165, 250, 0.15)",
                  }}
                >
                  <th
                    style={{
                      padding: "1rem",
                      textAlign: "left",
                      color: "#60a5fa",
                      fontWeight: "bold",
                      width: "20%",
                      borderBottom: "2px solid rgba(96, 165, 250, 0.2)",
                    }}
                  >
                    Phase
                  </th>
                  <th
                    style={{
                      padding: "1rem",
                      textAlign: "left",
                      color: "#60a5fa",
                      fontWeight: "bold",
                      width: "40%",
                      borderBottom: "2px solid rgba(96, 165, 250, 0.2)",
                    }}
                  >
                    Process
                  </th>
                  <th
                    style={{
                      padding: "1rem",
                      textAlign: "left",
                      color: "#60a5fa",
                      fontWeight: "bold",
                      width: "40%",
                      borderBottom: "2px solid rgba(96, 165, 250, 0.2)",
                    }}
                  >
                    Analogy
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    phase: "Training",
                    process: "Vaccine introduces harmless version",
                    analogy: 'Security team studies "wanted poster"',
                  },
                  {
                    phase: "Learning",
                    process: "Memory B-cells created",
                    analogy: "Security team memorizes threat",
                  },
                  {
                    phase: "Protection",
                    process: "Antibody production ready",
                    analogy: "Security team on standby",
                  },
                ].map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      background:
                        index % 2 === 0
                          ? "transparent"
                          : "rgba(96, 165, 250, 0.05)",
                    }}
                  >
                    <td
                      style={{
                        padding: "1rem",
                        borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
                        color: "#60a5fa",
                        fontWeight: "600",
                      }}
                    >
                      {row.phase}
                    </td>
                    <td
                      style={{
                        padding: "1rem",
                        borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
                        color: "rgba(255, 255, 255, 0.9)",
                      }}
                    >
                      {row.process}
                    </td>
                    <td
                      style={{
                        padding: "1rem",
                        borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
                        color: "rgba(255, 255, 255, 0.9)",
                      }}
                    >
                      {row.analogy}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            style={{
              marginTop: "2.5rem",
              padding: "1.5rem",
              background: "rgba(52, 211, 153, 0.05)",
              borderRadius: "8px",
              border: "1px solid rgba(52, 211, 153, 0.2)",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h3
              style={{
                margin: 0,
                marginBottom: "1rem",
                color: "#34d399",
                fontSize: "1.25rem",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              Key Benefits
            </h3>
            <div
              style={{
                display: "grid",
                gap: "1rem",
              }}
            >
              {[
                "Quick immune response",
                "Long-lasting protection",
                "Prevents severe illness",
              ].map((benefit, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.75rem",
                    color: "rgba(255, 255, 255, 0.9)",
                    fontSize: "1rem",
                  }}
                >
                  <span
                    style={{
                      color: "#34d399",
                      fontSize: "1.25rem",
                    }}
                  >
                    ✓
                  </span>
                  {benefit}
                </div>
              ))}
            </div>
          </div>
        </div>
      ),
    },
    {
      type: "user",
      content: "How about some help with coding algorithms?",
    },
    {
      type: "assistant",
      content: (
        <div
          style={{
            marginBottom: "2.5rem",
            paddingBottom: "1.5rem",
            borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
            position: "relative",
          }}
        >
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "600",
              color: "#60a5fa",
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                background: "linear-gradient(90deg, #60a5fa 0%, #34d399 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                paddingBottom: "2px",
                borderBottom: "2px solid rgba(96, 165, 250, 0.2)",
              }}
            >
              Binary Search Algorithm
            </span>
          </h2>
          <p
            style={{
              marginTop: "1rem",
              color: "rgba(255, 255, 255, 0.9)",
              fontSize: "1rem",
              lineHeight: "1.5",
              maxWidth: "90%",
            }}
          >
            Let's implement this efficient search strategy!
          </p>

          <div className={styles.box} style={{ marginTop: "20px" }}>
            <div className={styles.boxTitle}>
              <span>Binary Search Algorithm</span>
            </div>
            <div className={styles.boxContent}>
              {/* Code Block */}
              <div className={styles.codeBlock}>
                <SyntaxHighlighter
                  language="python"
                  style={atomDark}
                  customStyle={{
                    background: "rgba(15, 23, 42, 0.6)",
                    padding: "16px",
                    borderRadius: "8px",
                    fontSize: "14px",
                  }}
                >
                  {`def binary_search(arr, target):
    left = 0              # Start of array
    right = len(arr) - 1  # End of array
    
    while left <= right:
        mid = (left + right) // 2  # Find middle element
        
        if arr[mid] == target:
            return mid         # Found the target
        elif arr[mid] < target:
            left = mid + 1    # Search right half
        else:
            right = mid - 1   # Search left half
    
    return -1  # Target not found`}
                </SyntaxHighlighter>
              </div>

              {/* Complexity Table */}
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Array Size</th>
                    <th>Steps</th>
                    <th>Time Complexity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>8 elements</td>
                    <td>3 steps</td>
                    <td>O(log n)</td>
                  </tr>
                  <tr>
                    <td>16 elements</td>
                    <td>4 steps</td>
                    <td>O(log n)</td>
                  </tr>
                  <tr>
                    <td>1024 elements</td>
                    <td>10 steps</td>
                    <td>O(log n)</td>
                  </tr>
                </tbody>
              </table>

              {/* Key Insight */}
              <div className={styles.insight}>
                <span>⚡ Binary Search is much faster than linear search!</span>
              </div>

              {/* Applications */}
              <div className={styles.applications}>
                <div className={styles.insight}>
                  <span>Applications</span>
                </div>
                <ul>
                  <li>Phone book search</li>
                  <li>Database indexing</li>
                  <li>Game number guessing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      type: "user",
      content:
        "I'm having trouble understanding wave interference in physics. Could you explain how waves interact?",
    },
    {
      type: "assistant",
      content: (
        <div
          style={{
            marginBottom: "2.5rem",
            paddingBottom: "1.5rem",
            borderBottom: "1px solid rgba(96, 165, 250, 0.1)",
            position: "relative",
          }}
        >
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "600",
              color: "#60a5fa",
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                background: "linear-gradient(90deg, #60a5fa 0%, #34d399 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                paddingBottom: "2px",
                borderBottom: "2px solid rgba(96, 165, 250, 0.2)",
              }}
            >
              Wave Interference Patterns
            </span>
          </h2>
          <p
            style={{
              marginTop: "1rem",
              color: "rgba(255, 255, 255, 0.9)",
              fontSize: "1rem",
              lineHeight: "1.5",
              maxWidth: "90%",
            }}
          >
            When two waves meet, they combine through superposition. Let's
            observe how waves with the same frequency can create different
            interference patterns.
          </p>

          <div className={styles.graphContainer}>
            <svg width="100%" height="100%" viewBox="0 0 400 200">
              {/* Axis lines */}
              <line
                x1="40"
                y1="100"
                x2="360"
                y2="100"
                stroke="rgba(255,255,255,0.2)"
                strokeWidth="1"
                strokeDasharray="5,5"
              />

              {/* Wave 1 - Blue */}
              <motion.path
                d="M 40 100 C 80 60 120 60 160 100 C 200 140 240 140 280 100 C 320 60 360 60 360 100"
                fill="none"
                stroke="#60a5fa"
                strokeWidth="2"
                strokeOpacity="0.6"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 2, ease: "easeInOut" }}
              />

              {/* Wave 2 - Red */}
              <motion.path
                d="M 40 100 C 80 140 120 140 160 100 C 200 60 240 60 280 100 C 320 140 360 140 360 100"
                fill="none"
                stroke="#ef4444"
                strokeWidth="2"
                strokeOpacity="0.6"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 2, ease: "easeInOut" }}
              />

              {/* Resultant Wave - Purple */}
              <motion.path
                d="M 40 100 C 80 20 120 20 160 100 C 200 180 240 180 280 100 C 320 20 360 20 360 100"
                fill="none"
                stroke="#a855f7"
                strokeWidth="3"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 2, ease: "easeInOut", delay: 1 }}
              />

              {/* Labels */}
              <text x="50" y="50" fill="#60a5fa" fontSize="12">
                Wave 1
              </text>
              <text x="50" y="150" fill="#ef4444" fontSize="12">
                Wave 2
              </text>
              <text x="320" y="40" fill="#a855f7" fontSize="12">
                Resultant Wave
              </text>

              {/* Amplitude markers */}
              <text x="20" y="20" fill="white" fontSize="12">
                2A
              </text>
              <text x="20" y="180" fill="white" fontSize="12">
                -2A
              </text>
              <text x="20" y="105" fill="white" fontSize="12">
                0
              </text>
            </svg>
          </div>

          <div className={styles.infoBox}>
            <p>
              When waves are in phase, they create constructive interference
              (amplitude doubles). When out of phase, they create destructive
              interference (cancel out).
            </p>
          </div>
        </div>
      ),
    },
    {
      type: "user",
      content: "Thank you Lumi!",
    },
    {
      type: "assistant",
      content: (
        <div
          style={{
            marginBottom: "1rem",
            color: "rgba(255, 255, 255, 0.9)",
          }}
        >
          <p style={{ margin: 0, fontSize: "1.1rem", lineHeight: 1.5 }}>
            You're welcome! I'm always here to help you learn and grow. Feel
            free to ask me anything!
            <span style={{ marginLeft: "4px" }}>✨</span>
          </p>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container} ref={demoRef}>
      <div className={styles.header}>
        <div className={styles.headerLogo}>
          <img src={aiStar} alt="Lumi AI Assistant" />
        </div>
        <h2
          style={{
            fontSize: "1.5rem",
            fontWeight: "600",
            margin: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              background:
                "linear-gradient(135deg, #f0f9ff 0%, #60a5fa 50%, #3b82f6 100%)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Lumi
          </span>
        </h2>
      </div>
      <div className={styles.chatArea} ref={chatAreaRef}>
        <div className={styles.messagesContainer}>
          <AnimatePresence>
            {messages.map((message, index) => (
              <Message key={index} message={message} />
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default ModernDemo;
