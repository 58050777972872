import { Routes, Route } from 'react-router-dom';
import LandingPage from "./views/landing";
import HomePage from "./components/HomePage";
import Resources from "./views/resources";
import PricingPage from './views/pricing';
import ContactPage from './views/contact';
import About from './views/about';
import AITechnology from './views/ai-technology';
import Research from './views/research';
import Process from './views/process';
import AuthPage from './views/auth';
import SidePanel from './components/SidePanel';
import { ThemeProvider } from "./context/ThemeContext";
import { AuthProvider } from "./context/AuthContext";
import { SidePanelProvider } from "./context/SidePanelContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <SidePanelProvider>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/explore-ai-technology" element={<AITechnology />} />
            <Route path="/view-research" element={<Research />} />
            <Route path="/see-our-process" element={<Process />} />
            <Route path="/auth" element={<AuthPage />} />
          </Routes>
          <SidePanel />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </SidePanelProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
