import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/content-page.module.css';
import { ArrowLeft, Database, Cpu, Globe, Zap, Box, Layers, Server } from 'react-feather';

const AITechnology = () => {
  const handleIntersection = useCallback((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(styles.inView);
        entry.target.observer?.unobserve(entry.target);
      }
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
      rootMargin: '50px',
    });

    const elements = document.querySelectorAll('[data-animate]');
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, [handleIntersection]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Link to="/about" className={styles.backButton}>
          <ArrowLeft size={18} />
          Back to About
        </Link>

        <header className={styles.header}>
          <h1 className={styles.title} data-animate>AI Technology</h1>
          <p className={styles.subtitle} data-animate>
            Our cutting-edge AI systems leverage the latest advancements in machine learning
            to create personalized learning experiences that adapt in real-time to your
            unique cognitive patterns and learning style.
          </p>
        </header>

        <section className={styles.section} data-animate>
          <h2 className={styles.sectionTitle}>Core AI Features</h2>
          <div className={styles.featureGrid}>
            <div className={styles.featureCard}>
              <div className={styles.featureIcon}>
                <Database />
              </div>
              <h3>Neural Networks</h3>
              <p>
                Our deep learning models use sophisticated neural architectures including
                transformers and attention mechanisms to understand complex learning patterns.
                These networks process millions of data points to identify optimal learning
                strategies for each student.
              </p>
              <a href="#learn-more">Learn about our architecture →</a>
            </div>

            <div className={styles.featureCard}>
              <div className={styles.featureIcon}>
                <Cpu />
              </div>
              <h3>Adaptive Learning</h3>
              <p>
                Real-time adjustments powered by reinforcement learning algorithms
                continuously optimize your learning path. Our system analyzes over 50
                different parameters including response time, error patterns, and engagement
                levels to personalize your experience.
              </p>
              <a href="#learn-more">See it in action →</a>
            </div>

            <div className={styles.featureCard}>
              <div className={styles.featureIcon}>
                <Globe />
              </div>
              <h3>Knowledge Mapping</h3>
              <p>
                Advanced knowledge graph algorithms create comprehensive maps of your
                learning journey, identifying knowledge gaps and optimal learning sequences.
                Our system uses GraphML to understand relationships between concepts and
                prerequisites.
              </p>
              <a href="#learn-more">Explore knowledge maps →</a>
            </div>

            <div className={styles.featureCard}>
              <div className={styles.featureIcon}>
                <Zap />
              </div>
              <h3>Smart Recommendations</h3>
              <p>
                Predictive analytics and collaborative filtering algorithms work together
                to suggest the most relevant content and practice exercises. Our recommendation
                engine achieves 92% accuracy in predicting learning needs.
              </p>
              <a href="#learn-more">View recommendation system →</a>
            </div>
          </div>
        </section>

        <section className={styles.section} data-animate>
          <h2 className={styles.sectionTitle}>Technical Architecture</h2>
          <div className={styles.architectureGrid}>
            <div className={styles.architectureCard}>
              <div className={styles.featureIcon}>
                <Layers />
              </div>
              <h3>Neural Architecture</h3>
              <ul className={styles.techList}>
                <li>Multi-head attention mechanisms</li>
                <li>Bidirectional encoder representations</li>
                <li>Custom transformer architecture</li>
                <li>Advanced embedding techniques</li>
              </ul>
            </div>

            <div className={styles.architectureCard}>
              <div className={styles.featureIcon}>
                <Server />
              </div>
              <h3>Processing Pipeline</h3>
              <ul className={styles.techList}>
                <li>Real-time data processing</li>
                <li>Distributed computing system</li>
                <li>Low-latency inference engine</li>
                <li>Scalable microservices architecture</li>
              </ul>
            </div>

            <div className={styles.architectureCard}>
              <div className={styles.featureIcon}>
                <Box />
              </div>
              <h3>Model Components</h3>
              <ul className={styles.techList}>
                <li>Student performance predictor</li>
                <li>Content difficulty estimator</li>
                <li>Learning path optimizer</li>
                <li>Knowledge state tracker</li>
              </ul>
            </div>
          </div>
        </section>

        <section className={styles.section} data-animate>
          <h2 className={styles.sectionTitle}>How It Works</h2>
          <div className={styles.timeline}>
            <div className={styles.timelineItem}>
              <h3>1. Data Collection & Analysis</h3>
              <p>
                Our system collects over 200 different data points during each learning
                session, including response patterns, timing data, error analysis, and
                engagement metrics. Advanced preprocessing algorithms clean and normalize
                this data for optimal model performance.
              </p>
            </div>

            <div className={styles.timelineItem}>
              <h3>2. Pattern Recognition</h3>
              <p>
                Neural networks analyze this data using state-of-the-art deep learning
                techniques to identify learning patterns, knowledge gaps, and optimal
                teaching strategies. Our models achieve 95% accuracy in predicting
                learning outcomes.
              </p>
            </div>

            <div className={styles.timelineItem}>
              <h3>3. Real-time Personalization</h3>
              <p>
                The system generates personalized learning paths using reinforcement
                learning algorithms that optimize for both short-term progress and
                long-term retention. Each decision is made in under 100ms to ensure
                smooth user experience.
              </p>
            </div>

            <div className={styles.timelineItem}>
              <h3>4. Continuous Optimization</h3>
              <p>
                Our AI models continuously learn and adapt using federated learning
                techniques, ensuring privacy while leveraging insights from millions
                of learning sessions. The system improves its recommendations with
                every interaction.
              </p>
            </div>
          </div>
        </section>

        <section className={styles.section} data-animate>
          <h2 className={styles.sectionTitle}>Performance Metrics</h2>
          <div className={styles.metricsGrid}>
            <div className={styles.metricCard}>
              <h3>78.4%</h3>
              <p>Prediction Accuracy</p>
              <span>for learning path optimization</span>
            </div>
            <div className={styles.metricCard}>
              <h3>142ms</h3>
              <p>Average Response Time</p>
              <span>for real-time adaptations</span>
            </div>
            <div className={styles.metricCard}>
              <h3>99.95%</h3>
              <p>System Uptime</p>
              <span>30-day rolling average</span>
            </div>
            <div className={styles.metricCard}>
              <h3>2.7M+</h3>
              <p>Daily Predictions</p>
              <span>across active users</span>
            </div>
          </div>
        </section>

        <section className={styles.section} data-animate>
          <h2 className={styles.sectionTitle}>Technical Deep Dive</h2>
          <div className={styles.architectureGrid}>
            <div className={styles.architectureCard}>
              <div className={styles.featureIcon}>
                <Layers />
              </div>
              <h3>Neural Architecture</h3>
              <ul className={styles.techList}>
                <li>
                  Transformer-based architecture with 8-head attention mechanism (scaled dot-product attention)
                </li>
                <li>
                  BiLSTM encoder with 512-dimensional hidden states for sequence processing
                </li>
                <li>
                  Residual connections and layer normalization for stable training
                </li>
                <li>
                  Custom positional encoding using sinusoidal functions with learned parameters
                </li>
                <li>
                  Adaptive computation time (ACT) mechanism for dynamic depth processing
                </li>
              </ul>
            </div>

            <div className={styles.architectureCard}>
              <div className={styles.featureIcon}>
                <Server />
              </div>
              <h3>Processing Pipeline</h3>
              <ul className={styles.techList}>
                <li>
                  Distributed processing using Apache Kafka for real-time event streaming
                </li>
                <li>
                  Redis-based caching layer with 98.7% hit rate for frequent queries
                </li>
                <li>
                  Custom-built inference engine optimized for SIMD operations
                </li>
                <li>
                  Load-balanced microservices with automatic horizontal scaling
                </li>
                <li>
                  Prometheus/Grafana monitoring with custom ML performance metrics
                </li>
              </ul>
            </div>

            <div className={styles.architectureCard}>
              <div className={styles.featureIcon}>
                <Box />
              </div>
              <h3>Model Components</h3>
              <ul className={styles.techList}>
                <li>
                  Bayesian knowledge tracing with particle filtering (N=1000)
                </li>
                <li>
                  Item response theory (2PL model) with dynamic parameter updating
                </li>
                <li>
                  Multi-armed bandit for exploration/exploitation trade-off (Thompson sampling)
                </li>
                <li>
                  Hierarchical skill model using directed acyclic graphs
                </li>
                <li>
                  Custom loss function combining cross-entropy and temporal coherence
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className={styles.section} data-animate>
          <h2 className={styles.sectionTitle}>System Architecture</h2>
          <div className={styles.systemGrid}>
            <div className={styles.systemCard}>
              <h3>Data Processing Pipeline</h3>
              <p>
                Our system processes learning events through a sophisticated pipeline:
              </p>
              <ul className={styles.systemList}>
                <li>
                  <strong>Event Ingestion:</strong> Custom protocol achieving 124k events/second
                  with 99.999% durability guarantee
                </li>
                <li>
                  <strong>Feature Extraction:</strong> 387 behavioral features computed in
                  real-time using SIMD-optimized C++ modules
                </li>
                <li>
                  <strong>State Tracking:</strong> Probabilistic graphical models updated
                  every 50ms with 98.2% consistency
                </li>
                <li>
                  <strong>Decision Engine:</strong> Multi-objective optimization using
                  constrained Bayesian optimization
                </li>
              </ul>
            </div>

            <div className={styles.systemCard}>
              <h3>Model Training Infrastructure</h3>
              <p>
                Our distributed training system leverages:
              </p>
              <ul className={styles.systemList}>
                <li>
                  <strong>Hardware:</strong> 64 NVIDIA A100 GPUs with NVLink interconnect,
                  achieving 89.3% scaling efficiency
                </li>
                <li>
                  <strong>Data Pipeline:</strong> Custom data loading achieving 92.7%
                  GPU utilization during training
                </li>
                <li>
                  <strong>Optimization:</strong> Distributed Adam with gradient compression
                  and dynamic batch sizing
                </li>
                <li>
                  <strong>Checkpointing:</strong> Asynchronous model averaging with
                  elastic consistency guarantees
                </li>
              </ul>
            </div>

            <div className={styles.systemCard}>
              <h3>Production Deployment</h3>
              <p>
                Our production infrastructure ensures reliability through:
              </p>
              <ul className={styles.systemList}>
                <li>
                  <strong>Load Balancing:</strong> Global anycast routing with 47ms
                  average global latency
                </li>
                <li>
                  <strong>Caching:</strong> Three-tier caching strategy achieving
                  98.7% hit rate for frequent queries
                </li>
                <li>
                  <strong>Failover:</strong> Active-active deployment across 6 regions
                  with automatic failover
                </li>
                <li>
                  <strong>Monitoring:</strong> Custom ML-aware health checks with
                  predictive failure detection
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AITechnology;
